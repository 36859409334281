<div class="position-relative "
    (clickOutside)="isShow = false">
  <div class="row">
    <div class="col select pointer"
        [ngClass]="{'straight-bottom': isShow, 'disabled': disable}"
        (click)="showDropdown()">
      <ng-container *ngIf="checkAllDepartment; else notAllDepartment">
        <span class="item"
            [ngClass]="{'m-0 p-0': checkAllDepartment}">
          <span class="pointer close-border"
              style="z-index: 100;"
              (click)="closeAllDepartment()"
              *ngIf="multiple">
            ×</span>
          <span class="ps-1">
            {{translate.currentLang === 'th' ? ALL_DATA.display_name : ALL_DATA.display_name_en}}
          </span>
        </span>
      </ng-container>
      <ng-template #notAllDepartment>
        <span *ngFor="let item of items; let i = index"
            class="item">
          <span class="pointer close-border"
              style="z-index: 100;"
              (click)="deleteItem(i)"
              *ngIf="multiple">
            ×</span>
          <span class="ps-1">
            {{translate.currentLang === 'th' ? item.display_name : item.display_name_en}}
          </span>
        </span>
      </ng-template>

      <span *ngIf="items.length === 0"
          class="placeholder"> {{ placeholder | translate}}</span>
      <div *ngIf="loadingSpinner"
          class="position-absolute position-spinner">
        <span class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"></span>
      </div>
      <i *ngIf="!loadingSpinner"
          class="pt-2 fal fa-chevron-down position-absolute grey end-0 fs-base-l4"
          [ngClass]="{'fa-chevron-up': isShow, 'm-0' : !checkAllDepartment}"
          style="padding: 19px;"></i>
      <i class="fas fa-times red position-absolute pointer fs-base-l6"
          *ngIf="!multiple && items.length > 0"
          style="right: 7%;top: 12px;"
          (click)="clearValue()"></i>
    </div>
  </div>
  <div class="border dropdown border-top-0"
      [ngClass]="{'d-none': !isShow || !nodes}">
    <tree-root #treeRoot
        [focused]="true"
        (activate)="onActivate($event)"
        (select)="nodeChecked($event.node, true)"
        (deselect)="nodeChecked($event.node, false)"
        [nodes]="nodes"
        [options]="options">
      <ng-template #treeNodeTemplate
          let-node>
        <ng-container>
          {{translate.currentLang === 'th' ? node.data.department_name : node.data.department_name_en}}
        </ng-container>
      </ng-template>
    </tree-root>
  </div>
</div>