<app-modal-confirmation>
  <ng-template appCustomModalHeader>
    <div class="modal-header">
      <h4 class="fs-lg-u7 fs-u3 fw-bold mb-0 mx-2">
        <span *ngIf="headerIconPath"
            class="me-2">
          <svg alt="Check Circle"
              height="24"
              width="24"
              viewBox="0 0 16 16"
              [style.color]="'rgb(23, 80, 192)'">
            <circle cx="50%"
                cy="50%"
                r="50%"
                [attr.fill]="'#e8eefb'" />
            <use height="12"
                width="12"
                x="2"
                y="2"
                stroke="currentColor"
                [attr.href]="headerIconPath">
            </use>
          </svg>
        </span>{{ header || 'COMMON.Confirm' | translate }}
      </h4>
      <button *ngIf="!headerCloseButton"
          aria-label="Close"
          class="btn btn-std-outline-black-light btn-icon mx-3"
          type="button"
          (click)="onDismissClick()">
        <span class="far fa-times fa-lg"></span>
      </button>
    </div>
  </ng-template>
  <ng-template appCustomModalBody>
    <div class="modal-body"
        [ngClass]="bodyNotDesignDownload ? 'p-4' : 'p-3 p-lg-4 mt-3'">
      <div [ngClass]="{'d-flex flex-column align-items-center justify-content-center w-100': !bodyNotDesignDownload }">
        <div *ngIf="imageRef"
            [ngClass]="{'mb-3 mb-lg-4': !bodyNotDesignDownload}">
          <ng-container [ngTemplateOutlet]="imageRef"></ng-container>
        </div>
        <label *ngIf="!bodyNotDesignDownload"
            class="fs-u1 fs-lg-u2 fw-md-medium text-center mb-1">{{ title }}</label>
        <label *ngIf="!bodyNotDesignDownload"
            class="fs-l2 fw-normal text-center text-gray-neutrals mb-0">{{ subtitle }}</label>
        <div *ngIf="!hideButton"
            class="d-flex align-items-center"
            [ngClass]="bodyNotDesignDownload ? 'justify-content-end mt-4':'justify-content-center my-3 my-lg-4'">
          <button class="btn btn-std btn-std-2ry fs-base-l4 fw-semibold mx-1 py-base-l8 px-base-l4 btn-width"
              type="button"
              [disabled]="isLoading"
              (click)="onDismissClick()">
            {{ dismissText }}
          </button>
          <button class="btn btn-std btn-std-prim fs-base-l4 mx-1 py-base-l8 "
              type="button"
              [disabled]="isSubmitDisabled"
              [ladda]="isLoading"
              (click)="onSubmitClick()">
            {{ submitText }}
          </button>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template appCustomModalFooter></ng-template>
</app-modal-confirmation>