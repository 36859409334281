<div class="comment-container">

  <textarea *ngIf="isNonUser"
      rows="1"
      placeholder="{{'MEMOS.COMMENT-HERE'|translate}}"
      autosize
      [(ngModel)]="newComment"
      [minRows]="1"
      [maxRows]="6"
      (keydown.enter)="handleEnterKey($event)">
  </textarea>

  <div #messageInput
      id="messageInput"
      *ngIf="!isNonUser"
      class="textarea-post post-width custom-box"
      [attr.placeholder]="'Aa'"
      [(ngModel)]="newComment"
      [minRows]="1"
      [maxRows]="6"
      [mention]="profileList"
      [mentionConfig]="mentionConfig"
      (searchTerm)="triggerDebouncer($event)"
      (opened)="mentionOpened()"
      (closed)="mentionClosed()"
      (keydown.enter)="handleEnterKey($event)"
      [preventEnter]="true"
      autosize
      appContentEditable
      appMentionHighlight>
  </div>
  <svg class="pointer comment-icon icon-attachment"
      (click)="image.click()"
      stroke="currentColor"
      height="20"
      width="20">
    <use href="assets/images/icons/objects/paperclip.svg#paperclip"
        height="20"
        width="20"
        (click)="addComment()">
    </use>
  </svg>
  <button class="border-icon-send"
      (click)="addComment()"
      [disabled]="isLoadingComment || !newComment">
    <svg class="pointer comment-icon"
        stroke="currentColor"
        height="20"
        width="20">
      <use href="assets/images/icons/communications/paper-plane.svg#paper-plane"
          height="20"
          width="20">
      </use>
    </svg>
  </button>
  <input type="file"
      accept="audio/*,video/*,image/*,.csv,.ppt,.pptx,.pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
      application/vnd.ms-excel, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      class="form-control m-t-10"
      [(ngModel)]="fileUpload"
      (change)="uploadFile($event)"
      [multiple]="true"
      #image
      hidden>
</div>