import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { UsersService } from '../../../modules/users/shared/users.service';
import { SpinnerService } from '../../../core/services/spinner.service';
import { finalize } from 'rxjs/operators';
import { AlertService } from '../../../core/services/alert.service';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-sign-signature',
  templateUrl: './sign-signature.component.html',
  styleUrls: ['./sign-signature.component.scss'],
})
export class SignSignatureComponent implements OnInit {
  @ViewChild('imageCropper', { static: false })
  cropperElement: ElementRef;
  @ViewChild('openModal') openModal: ElementRef;

  @Output() closeEvent = new EventEmitter();

  imageChangedEvent: string;
  croppedImage: string | any;
  chevronLeft = faChevronLeft;
  submitted = false;
  profileId: number;
  signature: any;
  imageURL: string | any;
  fileInput: any;
  blobOutput: any;
  errorMsg: string;
  binaryFile: any;
  signaturePreview: string | ArrayBuffer | any;

  constructor(
    private alert: AlertService,
    private spinner: SpinnerService,
    private service: UsersService,
    private auth: AuthenticationService,
    private modalService: NgbModal,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.profileId = this.store.selectSnapshot(
      (store) => store.auth.id,
    );
    this.spinner.show();
    this.service.getUserDetail(this.profileId).subscribe((user) => {
      this.signature = user.signature;
      this.signaturePreview = user.signature;
      this.spinner.hide();
      this.openModal.nativeElement.click();
    });
  }

  open(content: any) {
    this.modalService
      .open(content, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
      })
      .result.then(
        (result) => {
          this.imageChangedEvent = '';
          this.croppedImage = '';
          this.imageURL = '';
          this.fileInput = '';
        },
        (error) => {
          this.closeEvent.emit();
        },
      );
    this.signature = '';
  }

  imageLoaded() {}

  imageCropped(output: ImageCroppedEvent | any) {
    this.croppedImage = output.base64;
    this.blobOutput = this.base64ToFile(output.base64);
  }

  loadImageFailed() {
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.errorMsg =
      'ไม่สามารถประมวลผลภาพได้ กรุณาอัปโหลดภาพใหม่อีกครั้ง';
  }

  saveSignature() {
    this.binaryFile = this.convertFile(this.blobOutput);
    const reader = new FileReader();
    reader.readAsDataURL(this.binaryFile);
    reader.onload = () => {
      this.signaturePreview = reader.result;
    };
    this.uploadSignature();
    this.modalService.dismissAll();
    this.closeEvent.emit();
  }

  fileChangeEvent(file: any): void {
    this.errorMsg = '';
    this.imageChangedEvent = file;
    this.fileInput = file.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file.target.files[0]);
    reader.onload = () => {
      // this.signature = reader.result;
    };
  }

  onDraw(output: any) {
    this.imageChangedEvent = '';
    this.imageURL = output;
    this.fileInput = output;
  }

  convertFile(blob: Blob) {
    return new File(
      [blob],
      this.fileInput?.name ? this.fileInput?.name : 'photo.png',
      {
        type: blob?.type,
      },
    );
  }

  base64ToFile(base64Image: string): Blob {
    const split = base64Image.split(',');
    const type = split[0].replace('data:', '').replace(';base64', '');
    const byteString = atob(split[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type });
  }

  uploadSignature() {
    const fd = new FormData();
    if (this.binaryFile) {
      fd.append('signature', this.binaryFile);
    }

    this.spinner.show();
    this.service
      .updateUserBlob(fd, this.profileId)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe(
        () => {
          this.alert.success('Update signature success');
        },
        () => this.alert.error(),
      );
  }

  onClose() {
    this.closeEvent.emit();
  }
}
