<div class="my-3 box-validate-password"
    *ngIf="passwordPolicy">
  <p class="text-asterisk mb-1"
      *ngIf="passwordPolicy.is_needed_lower_case"
      [ngClass]="{ 'text-green': checkLowerCase }">
    <i class="fa"
        [ngClass]="checkLowerCase ? 'fa-check' : 'fa-times'"
        aria-hidden="true"></i>
    <b class="ps-1">{{'VALIDATION.LOWER-CHARACTER' | translate}}</b>
  </p>
  <p class="text-asterisk mb-1"
      *ngIf="passwordPolicy.is_needed_upper_case"
      [ngClass]="{ 'text-green': checkUpperCase }">
    <i class="fa"
        [ngClass]="checkUpperCase ? 'fa-check' : 'fa-times'"
        aria-hidden="true"></i>
    <b class="ps-1">{{'VALIDATION.UPPER-CHARACTER' | translate}}</b>
  </p>
  <p class="text-asterisk mb-1"
      *ngIf="passwordPolicy.is_needed_digit"
      [ngClass]="{ 'text-green': checkNumber }">
    <i class="fa"
        [ngClass]="checkNumber ? 'fa-check' : 'fa-times'"
        aria-hidden="true"></i>
    <b class="ps-1">{{'VALIDATION.DIGIT-CHARACTER' | translate}}</b>
  </p>
  <p class="text-asterisk mb-1"
      *ngIf="passwordPolicy.is_needed_special_case"
      [ngClass]="{ 'text-green': checkSymbols }">
    <i class="fa"
        [ngClass]="checkSymbols ? 'fa-check' : 'fa-times'"
        aria-hidden="true"></i>
    <b class="ps-1">{{'VALIDATION.SPECIAL-CHARACTER' | translate}}</b>
  </p>
  <p class="text-asterisk mb-0"
      [ngClass]="{ 'text-green': checkLength }">
    <i class="fa"
        [ngClass]="checkLength ? 'fa-check' : 'fa-times'"
        aria-hidden="true"></i>
    <b class="ps-1">{{
      'VALIDATION.LENGTH-CHARACTER' | translate
      }} {{passwordPolicy.minimum_length}} {{'VALIDATION.characters'|translate}}</b>
  </p>
</div>