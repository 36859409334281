import { BreakpointObserver } from '@angular/cdk/layout';
import {
  inject,
  Injectable,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { DeviceType } from '@shared/models/common.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ApiService } from 'src/app/core/http/api.service';
import { DrfResponse } from '../../modules/profile/shared/profile.models';
import { ThemeInfo } from '../../modules/setting/component/theme/shared/theme.models';
import { API_URL } from './api.constant';

export interface ThemeList {
  id: number;
  name: string;
  display_email: string;
  display_pdf: string;
  display_login: string;
  bg_login_color: string;
  bg_login_second_color: string;
  box_login_color: string;
  text_login_color: string;
  text_box_login_color: string;
  display_sidebar: string;
  bg_color_primary: string;
  bg_color_secondary: string;
  text_color: string;
  hovered_text_color: string;
  header_table_color: string;
  text_header_table_color: string;
  active: boolean;
  button_login_color: string;
  text_button_login_color: string;
}
@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  public currentTheme: any;
  private style: any;
  // tslint:disable-next-line:variable-name
  public small_logo = new Subject();
  // tslint:disable-next-line:variable-name
  public long_logo = new Subject();
  public isSidebarOpen = false;
  public stdHueColors: { [k: string]: number } = {
    primary: 341,
    secondary: 229,
  };
  public hslStdColorSets: HslColorSet[] = [
    {
      name: 'std-color-1',
      hueName: 'primary',
      saturation: '93%',
      lightness: '63%',
    },
    {
      name: 'std-color-2',
      hueName: 'primary',
      saturation: '47%',
      lightness: '93%',
    },
    {
      name: 'std-color-3', //primary default
      hueName: 'primary',
      saturation: '93%',
      lightness: '63%',
    },
    {
      name: 'std-color-4',
      hueName: 'secondary',
      saturation: '100%',
      lightness: '52.94%',
    },
    {
      name: 'std-color-5', //text
      hueName: 'primary',
      saturation: '100%',
      lightness: '100%',
    },
    {
      name: 'std-color-6', //primary hover
      hueName: 'primary',
      saturation: '57%',
      lightness: '46%',
    },
    {
      name: 'std-color-7', //primary pressed
      hueName: 'primary',
      saturation: '100%',
      lightness: '18%',
    },
    {
      name: 'std-color-8', //secondary default
      hueName: 'secondary',
      saturation: '28%',
      lightness: '92%',
    },
    {
      name: 'std-color-9', //secondary hover
      hueName: 'secondary',
      saturation: '93%',
      lightness: '28%',
    },
    {
      name: 'std-color-10', //secondary pressed
      hueName: 'secondary',
      saturation: '100%',
      lightness: '35%',
    },
    {
      name: 'std-color-11', // base background
      hueName: 'secondary',
      saturation: '33%',
      lightness: '98%',
    },
    {
      name: 'std-color-12', // highlight background
      hueName: 'primary',
      saturation: '50%',
      lightness: '98%',
    },
  ];
  themeList: ThemeList | any;
  private setTheme = new BehaviorSubject<ThemeList | any>(null);
  data = this.setTheme.asObservable();

  private renderer: Renderer2;
  private rendererFactory = inject(RendererFactory2);

  constructor(
    private http: ApiService,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  applyColorsToCssVar() {
    let cssText = '';
    Object.keys(this.stdHueColors).forEach((hueKey) => {
      cssText +=
        '--hue-' + hueKey + ':' + this.stdHueColors[hueKey] + '; ';
    });
    this.hslStdColorSets.forEach((color) => {
      cssText +=
        '--' + color.name + ':' + this.getHslColorValue(color) + ';';
      const lightness = parseFloat(
        color.lightness.match(/^(\d.)*%$/)?.[1] || '0',
      );
      // Create text color for contrast to background
      //* In future we can add case for lightness >= 50
      if (lightness < 50) {
        cssText += '--text-bg-' + color.name + ':' + '#fff' + ';';
      }
    });
    const styleEl = this.renderer.createElement('style');
    styleEl.innerHTML = `:root{${cssText}}`;
    const headEl = document.getElementsByTagName('head');
    this.renderer.appendChild(headEl.item(0), styleEl);
  }

  public fetchData(): Observable<DrfResponse<ThemeInfo>> {
    return this.http.get(API_URL.theme_color_list);
  }

  public loadTheme(id: number): Observable<ThemeInfo> {
    return this.http.get(API_URL.theme_color_list + id + '/');
  }

  public updateTheme(theme: any, id: number) {
    return this.http.patch(
      API_URL.theme_color_list + id + '/',
      theme,
    );
  }

  public createTheme(theme: any) {
    return this.http.post(API_URL.theme_color_list, theme);
  }

  public deleteTheme(id: any) {
    return this.http.delete(API_URL.theme_color_list + id + '/');
  }

  public removeEmailLogo(id: any) {
    return this.http.delete(
      API_URL.theme_color_list + id + '/logo-email/',
    );
  }

  /**
   * @param name A color name Ex. `std-color-1`
   */
  getHslColorSetByName(name: string): HslColorSet | undefined {
    return this.hslStdColorSets.find((color) => color.name === name);
  }

  /**
   * Example:
   * ```typescript
   * const hslColorSet: HslColorSet = {
   *   name: 'std-color-1',
   *   hueName: 'primary',
   *   saturation: '49%',
   *   lightness: '79%',
   * };
   * getHslColorValue(hslColorSet); // 173, 49%, 79%
   * ```

   * @param hslColorSet 
   * @returns the HSL color value example: 173, 49%, 79%
   */
  getHslColorValue(hslColorSet: HslColorSet): string {
    return (
      `${this.stdHueColors[hslColorSet.hueName]},` +
      ` ${hslColorSet.saturation}, ${hslColorSet.lightness}`
    );
  }

  /**
   * Get HSL color value by name.

   * Example:
   * ```typescript
   * getHslColorValueByName('std-color-1'); // 173, 49%, 79%
   * ```
   */
  getHslColorValueByName(name: string): string | undefined {
    const color = this.getHslColorSetByName(name);
    if (!color) {
      return;
    }
    return this.getHslColorValue(color);
  }

  // TODO: move to new service override BreakpointObserver class
  getDeviceTypeByBreakpoint(): DeviceType {
    if (this.isMobileSmallScreen()) {
      return 'mobile';
    } else if (this.isTablet()) {
      return 'tablet';
    } else {
      return 'desktop';
    }
  }

  public getTheme(): Observable<ThemeInfo> {
    return this.http.get(API_URL.theme_active);
  }

  isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 768px)');
  }

  isMobileSmallScreen() {
    return this.breakpointObserver.isMatched('(max-width: 576px)');
  }

  isTabletOrMobile() {
    return this.breakpointObserver.isMatched(['(max-width: 1024px)']);
  }

  isTablet() {
    return this.breakpointObserver.isMatched('(max-width: 1200px)');
  }

  isTabletOrMobilePortrait() {
    return this.breakpointObserver.isMatched([
      '(max-width: 1024px)',
      '(orientation: portrait)',
    ]);
  }

  setThemeList(theme: ThemeList) {
    this.themeList = theme;
    this.setTheme.next(this.themeList);
  }

  themeSetting() {
    this.setThemeList(this.themeList);
  }
}

export interface HslColorSet {
  name: string;
  hueName: string;
  saturation: string;
  lightness: string;
}
