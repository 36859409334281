<div class="d-flex"
    id="wrapper"
    [style.height]="'-webkit-fill-available'"
    *ngIf="navbarActive">
  <div class="d-flex flex-column"
      id="page-content-wrapper"
      [ngClass]="isUserActive()">
    <app-navbar [profile]="profile">
      <div class="position-absolute position-logo justify-content-center justify-content-md-start px-md-l7 px-lg-l6">
        <div class="btn-sidebar-toggle d-flex align-items-center px-base-l0 px-base-md-l2 ms-md-l7"
            [ngStyle]="{'color': themeList?.text_color}">
          <svg-icon class="icon-toggle-sidebar d-flex pointer align-self-center"
              [src]="deviceType === 'mobile'
                ? !openSidebar
                  ? 'assets/images/icons/graphics/bars.svg'
                  : 'assets/svg/icon-menu/menu-show-sidebar.svg'
                : isFullSidebar
                  ? 'assets/svg/icon-menu/menu-show-sidebar.svg'
                  : 'assets/svg/icon-menu/menu-hide-sidebar.svg'"
              [svgStyle]="{
                'height': 'inherit',
                'stroke':'#ffffff',
                'stroke-width.px': 1.5,
                'width': 'inherit'
              }"
              (click)="toggleSidebar()">
          </svg-icon>
        </div>
        <img alt=""
            [src]="'assets/images/logo-ngern-turbo.png'"
            [style.max-height.px]="50">
      </div>
    </app-navbar>
    <div class="d-flex position-relative flex-fill">
      <!-- Sidebar -->
      <div class="sidebar-wrapper"
          [class.sidebar-hidden]="!openSidebar">
        <div class="width-sidebar sidebar text-normal bg-std-color-1 d-flex flex-column bg-white"
            [class.sidebar-shrink]="!isFullSidebar"
            (window:resize)="onResize($event)">
          <div *ngIf="openSidebar"
              class="d-flex py-3 px-4 align-items-center">
            <app-profile-display [person]="profile"
                [size]="40"
                [pointer]="true"
                (clickProfile)="navigateToProfile()"></app-profile-display>
            <div class="d-flex px-3 flex-column">
              <div class="fw-semibold"
                  [style.font-size.px]="13">
                <span class="text-nowrap"
                    id="sidebar-user">
                  {{profileName}}
                </span>
              </div>
              <div class="pt-1"
                  [style.font-size.px]="9">
                <label class="pointer m-0 fw-normal"
                    (click)="changeLanguage('en')"
                    [class.fw-bold]="selectedLanguage === 'en'"
                    [class.text-bold]="selectedLanguage === 'en'">EN</label>
                <label class="fw-bold text-bold m-0"> \ </label>
                <label class="pointer m-0 fw-normal"
                    (click)="changeLanguage('th')"
                    [class.fw-bold]="selectedLanguage === 'th'"
                    [class.text-bold]="selectedLanguage === 'th'">TH</label>
              </div>
            </div>
          </div>
          <!-- /#sidebar-wrapper -->
          <div #sidebarSize
              class="list-group list-group-flush pt-0 pt-md-4 px-l7 px-lg-l6"
              [ngClass]="isFullSidebar ? 'text-start':'text-hidden'"
              (clickOutside)="closeSidebar($event)">
            <div class="menu-main overflow-auto"
                [style.--padding]="(isFullSidebar? '1.5':'1.5') + 'rem'">
              <!-- News Feed -->
              <div #rla="routerLinkActive"
                  class="mt-2"
                  routerLinkActive="active"
                  [routerLink]="['/', 'news-feed']"
                  ngbTooltip="{{'SIDEBAR.NEWS-FEED' | translate}}"
                  placement="right">
                <a class="list-group-item list-group-item-action">
                  <div [ngClass]="rla.isActive ? 'select-icon' : '' "></div>
                  <svg-icon src="assets/svg/icon-menu/blog.svg"
                      [svgStyle]="{
                        height: 'inherit',
                        'stroke': rla.isActive
                          ? 'hsl(' + activeSidebarColor + ')'
                          : themeList?.text_color,
                        width: 'inherit'
                      }">
                  </svg-icon>
                  <span class="ps-2 f-size-17 text-truncate-clip"
                      [style.color]=""
                      *ngIf="isFullSidebar"
                      [ngStyle]="{
                        color: rla.isActive
                          ? 'hsl(' + activeSidebarColor + ')'
                          : themeList?.text_color
                      }">
                    {{'SIDEBAR.NEWS-FEED'|translate}}
                  </span>
                </a>
              </div>
              <!-- Approval -->
              <div routerLinkActive="active"
                  [routerLink]="['/', 'pending-approval']"
                  #rla="routerLinkActive"
                  *appCheckPermission="['General']"
                  ngbTooltip="{{'SIDEBAR.PENDING' | translate}}"
                  placement="right">
                <a class="list-group-item list-group-item-action notification">
                  <div [ngClass]="rla.isActive ? 'select-icon' : '' "></div>
                  <svg-icon src="assets/svg/icon-menu/document-tasks.svg"
                      [stretch]="true"
                      [svgStyle]="{
                        height: 'inherit',
                        'stroke': rla.isActive
                          ? 'hsl(' + activeSidebarColor + ')'
                          : themeList?.text_color,
                        width: 'inherit'
                      }">
                  </svg-icon>
                  <span class="ps-2 f-size-17 text-truncate-clip"
                      *ngIf="isFullSidebar"
                      [ngStyle]="{
                        color: rla.isActive
                          ? 'hsl(' + activeSidebarColor + ')'
                          : themeList?.text_color
                      }">
                    {{'SIDEBAR.PENDING'|translate}}
                  </span>
                  <span class="d-inline-block"
                      [ngClass]="isFullSidebar ? 'badge-number': 'badge-number-hide'"
                      *ngIf="notificationService.memoNotificationCount > 0">
                    {{
                    notificationService.memoNotificationCount > 99 ? '99+' : notificationService.memoNotificationCount
                    }}
                  </span>
                </a>
              </div>
              <!-- Management -->
              <div class="custom-border custom-border-top"
                  *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                  [ngStyle]="{'background': departmentSubMenu? '': '' , 'color': themeList?.text_color}">
                <div routerLinkActive="active"
                    #rla="routerLinkActive"
                    class="mt-2 hasSubMenu"
                    *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                    [style.--color]="themeList?.hovered_text_color"
                    [style.--bg]="themeList?.hovered_text_color + '20'"
                    placement="right"
                    ngbTooltip="{{'SIDEBAR.MANAGEMENT'|translate}}"
                    (click)="departmentSubMenu=!departmentSubMenu">
                  <a class="list-group-item list-group-item-action position-relative ">
                    <div [class.select-icon]="rla.isActive"></div>
                    <svg-icon src="assets/svg/icon-menu/org.svg"
                        class="mt-title-svg"
                        [stretch]="true"
                        [svgStyle]="{
                          'fill': rla?.isActive
                            ? 'hsl(' + activeSidebarColor + ')'
                            : themeList?.text_color,
                          height: 'inherit',
                          width: 'inherit'
                        }">
                    </svg-icon>
                    <span class="w-100 f-size-17 pt-1 ps-2"
                        *ngIf="isFullSidebar"
                        [ngStyle]="{'color': themeList?.text_color}">{{'SIDEBAR.MANAGEMENT'|translate}}
                    </span>
                    <div class="text-end ps-2 position-absolute"
                        [ngStyle]="{'color': themeList?.text_color}"
                        [ngClass]="isFullSidebar? 'position-arrow' : 'position-arrow-hidden'">
                      <i class="fal"
                          [ngClass]=" departmentSubMenu ? 'fa-angle-up' : 'fa-angle-down'"></i>
                    </div>
                  </a>
                </div>
                <!-- Department level -->
                <ng-container *ngIf="departmentSubMenu">
                  <div routerLinkActive="active"
                      [routerLink]="['/', 'department', 'level']"
                      #rla="routerLinkActive"
                      [style.--color]="themeList?.hovered_text_color"
                      [style.--bg]="themeList?.hovered_text_color + '20'"
                      *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                      ngbTooltip="{{'SIDEBAR.DEPARTMENT-LEVEL' | translate}}"
                      placement="right">
                    <a class="list-group-item list-group-item-action">
                      <div [class.select-icon]="rla.isActive"></div>
                      <svg-icon src="assets/svg/icon-menu/department-level.svg"
                          [stretch]="true"
                          [svgStyle]="{
                            'fill': rla.isActive
                              ? 'hsl(' + activeSidebarColor + ')'
                              : themeList?.text_color,
                            width: 'inherit'
                          }">
                      </svg-icon>
                      <span class="ps-2 f-size-17"
                          *ngIf="isFullSidebar"
                          [ngStyle]="{
                            color: rla.isActive
                              ? 'hsl(' + activeSidebarColor + ')'
                              : themeList?.text_color
                          }">
                        {{'SIDEBAR.DEPARTMENT-LEVEL' | translate}}
                      </span>
                    </a>
                  </div>
                  <!-- Department -->
                  <div routerLinkActive="active"
                      [routerLink]="['/', 'department', 'add']"
                      #rla="routerLinkActive"
                      [style.--color]="themeList?.hovered_text_color"
                      [style.--bg]="themeList?.hovered_text_color + '20'"
                      *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                      ngbTooltip="{{'SIDEBAR.DEPARTMENT' | translate}}"
                      placement="right">
                    <a class="list-group-item list-group-item-action">
                      <!-- Department -->
                      <div [class.select-icon]="rla.isActive"></div>
                      <svg-icon src="assets/svg/icon-menu/events.svg"
                          [stretch]="true"
                          [svgStyle]="{
                            'fill': (rla.isActive
                              ? 'hsl(' + activeSidebarColor + ')'
                              : themeList?.text_color),
                            height: 'inherit',
                            width: 'inherit'
                          }">
                      </svg-icon>
                      <span class="ps-2 f-size-17"
                          *ngIf="isFullSidebar"
                          [ngStyle]="{
                            color: rla.isActive
                              ? 'hsl(' + activeSidebarColor + ')'
                              : themeList?.text_color,
                          }">
                        {{'SIDEBAR.DEPARTMENT' | translate}}
                      </span>
                    </a>
                  </div>
                  <!-- Manage Users -->
                  <div routerLinkActive="active"
                      [routerLink]="['/', 'users']"
                      #rla="routerLinkActive"
                      [style.--color]="themeList?.hovered_text_color"
                      [style.--bg]="themeList?.hovered_text_color + '20'"
                      *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                      ngbTooltip="{{'SIDEBAR.MANAGE-USERS' | translate}}"
                      placement="right">
                    <a class="list-group-item list-group-item-action">
                      <!-- Manage Users -->
                      <div [class.select-icon]="rla.isActive"></div>
                      <svg-icon src="assets/svg/icon-menu/user--profile.svg"
                          [stretch]="true"
                          [svgStyle]="{
                            'fill': rla.isActive
                              ? 'hsl(' + activeSidebarColor + ')'
                              : themeList?.text_color,
                            height: 'inherit',
                            width: 'inherit'
                          }">
                      </svg-icon>
                      <span class="ps-2 f-size-17"
                          *ngIf="isFullSidebar"
                          [ngStyle]="{
                            color: rla.isActive
                              ? 'hsl(' + activeSidebarColor + ')'
                              : themeList?.text_color,
                          }">
                        {{'SIDEBAR.MANAGE-USERS'|translate}}
                      </span>
                    </a>
                  </div>
                  <!-- LOA -->
                  <div routerLinkActive="active"
                      [routerLink]="['/', 'loa']"
                      #rla="routerLinkActive"
                      [style.--color]="themeList?.hovered_text_color"
                      [style.--bg]="themeList?.hovered_text_color + '20'"
                      *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                      ngbTooltip="{{'SIDEBAR.MANAGE-APPROVAL' | translate}}"
                      placement="right">
                    <a class="list-group-item list-group-item-action">
                      <div [class.select-icon]="rla.isActive"></div>
                      <svg-icon src="assets/svg/icon-menu/category.svg"
                          [stretch]="true"
                          [svgStyle]="{
                            'fill': rla.isActive
                              ? 'hsl(' + activeSidebarColor + ')'
                              : themeList?.text_color,
                            height: 'inherit',
                            width: 'inherit'
                          }">
                      </svg-icon>
                      <span class="ps-2 f-size-17"
                          *ngIf="isFullSidebar"
                          [ngStyle]="{
                            color: rla.isActive
                              ? 'hsl(' + activeSidebarColor + ')'
                              : themeList?.text_color,
                            width: 'inherit'
                          }">
                        {{'SIDEBAR.MANAGE-APPROVAL'|translate}}
                      </span>
                    </a>
                  </div>
                  <!-- CC Group -->
                  <div routerLinkActive="active"
                      [routerLink]="['/', 'cc-group']"
                      #rla="routerLinkActive"
                      [style.--color]="themeList?.hovered_text_color"
                      [style.--bg]="themeList?.hovered_text_color + '20'"
                      *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                      ngbTooltip="{{'SIDEBAR.MANAGE-CC-GROUP' | translate}}"
                      placement="right">
                    <a class="list-group-item list-group-item-action">
                      <div [class.select-icon]="rla.isActive"></div>
                      <svg-icon src="assets/svg/icon-menu/cc.svg"
                          [svgStyle]="{
                            'fill': rla.isActive
                              ? 'hsl(' + activeSidebarColor + ')'
                              : themeList?.text_color,
                            height: 'inherit',
                            width: 'inherit'
                          }">
                      </svg-icon>
                      <span class="ps-2 f-size-17"
                          *ngIf="isFullSidebar"
                          [ngStyle]="{
                            color: rla.isActive
                              ? 'hsl(' + activeSidebarColor + ')'
                              : themeList?.text_color,
                            width: 'inherit'
                          }">
                        {{'SIDEBAR.MANAGE-CC-GROUP'|translate}}
                      </span>
                    </a>
                  </div>
                  <!-- All Document (Trash) -->
                  <div routerLinkActive="active"
                      [routerLink]="['/', 'memos', 'trash']"
                      #rla="routerLinkActive"
                      [style.--color]="themeList?.hovered_text_color"
                      [style.--bg]="themeList?.hovered_text_color + '20'"
                      *appCheckPermission="['Admin', 'Master Admin']"
                      ngbTooltip="{{'SIDEBAR.ALL-DOCUMENTS' | translate}}"
                      placement="right">
                    <a class="list-group-item list-group-item-action">
                      <div [class.select-icon]="rla.isActive"></div>
                      <svg-icon src="assets/svg/icon-menu/document-tasks.svg"
                          [stretch]="true"
                          [svgStyle]="{
                            'stroke': rla.isActive
                              ? 'hsl(' + activeSidebarColor + ')'
                              : themeList?.text_color,
                            height: 'inherit',
                            width: 'inherit'
                          }">
                      </svg-icon>
                      <span class="ps-2 f-size-17"
                          *ngIf="isFullSidebar"
                          [ngStyle]="{
                            color: rla.isActive
                              ? 'hsl(' + activeSidebarColor + ')'
                              : themeList?.text_color,
                            width: 'inherit'
                          }">
                        {{'SIDEBAR.ALL-DOCUMENTS'|translate}}
                      </span>
                    </a>
                  </div>
                  <!-- upload memo type -->
                  <div routerLinkActive="active"
                      [routerLink]="['/', 'manage-types-upload-memo', 'type']"
                      #rla="routerLinkActive"
                      [style.--color]="themeList?.hovered_text_color"
                      [style.--bg]="themeList?.hovered_text_color + '20'"
                      *appCheckPermission="['Admin', 'Master Admin']"
                      ngbTooltip="{{'SIDEBAR.UPLOAD-MEMO-TYPE' | translate}}"
                      placement="right">
                    <a class="list-group-item list-group-item-action">
                      <div [class.select-icon]="rla.isActive"></div>
                      <svg-icon src="assets/svg/icon-menu/catalog.svg"
                          [stretch]="true"
                          [svgStyle]="{
                            'fill': (rla.isActive ? 'hsl(' + activeSidebarColor + ')' : themeList?.text_color),
                            height: 'inherit',
                            width: 'inherit'
                          }">
                      </svg-icon>
                      <span class="ps-2 f-size-17"
                          *ngIf="isFullSidebar"
                          [ngStyle]="{
                              color: rla.isActive
                              ? 'hsl(' + activeSidebarColor + ')'
                              : themeList?.text_color
                          }">
                        {{'SIDEBAR.UPLOAD-MEMO-TYPE' | translate}}
                      </span>
                    </a>
                  </div>
                </ng-container>
              </div>
              <!-- Certificate -->
              <ng-container *appFeatureFlag="'ddoc'">
                <div class="custom-border"
                    *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                    [ngStyle]="{'background': manageCertificate? '': '' , 'color': themeList?.text_color}">
                  <!-- manage certificate -->
                  <div routerLinkActive="active"
                      class="mt-2 hasSubMenu"
                      #rla="routerLinkActive"
                      *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                      [style.--color]="themeList?.hovered_text_color"
                      [style.--bg]="themeList?.hovered_text_color + '20'"
                      placement="right"
                      ngbTooltip="{{'CSR.CERTIFICATE'|translate}}"
                      (click)="manageCertificate=!manageCertificate">
                    <a class="list-group-item list-group-item-action position-relative">
                      <div [class.select-icon]="rla.isActive"></div>
                      <svg-icon src="assets/svg/icon-menu/document.svg"
                          class="mt-title-svg"
                          [stretch]="true"
                          [svgStyle]="{
                            'fill': rla?.isActive
                              ? 'hsl(' + activeSidebarColor + ')'
                              : themeList?.text_color,
                            height: 'inherit',
                            width: 'inherit'
                          }">
                      </svg-icon>
                      <span class="w-100 f-size-17 pt-1 ps-2"
                          *ngIf="isFullSidebar"
                          [ngStyle]="{'color': themeList?.text_color}">{{'CSR.CERTIFICATE'|translate}}</span>
                      <div class="text-end ps-2 position-absolute"
                          [ngClass]="isFullSidebar? 'position-arrow' : 'position-arrow-hidden'"
                          [ngStyle]="{'color': themeList?.text_color}">
                        <i class="fal"
                            [ngClass]=" manageCertificate ? 'fa-angle-up' : 'fa-angle-down'"></i>
                      </div>
                    </a>
                  </div>
                  <!-- manage csr -->
                  <ng-container *ngIf="manageCertificate">
                    <ng-container *appFeatureFlag="'ddoc_enable_feature_28'">
                      <div routerLinkActive="active"
                          [routerLink]="['/', 'manage-csr']"
                          #rla="routerLinkActive"
                          [style.--color]="themeList?.hovered_text_color"
                          [style.--bg]="themeList?.hovered_text_color + '20'"
                          *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                          ngbTooltip="{{'CSR.CSR-LIST' | translate}}"
                          placement="right">
                        <a class="list-group-item list-group-item-action">
                          <div [class.select-icon]="rla.isActive"></div>
                          <svg-icon src="assets/svg/icon-menu/carbon_request-quote.svg"
                              [stretch]="true"
                              [svgStyle]="{
                                'fill': (rla.isActive 
                                  ? 'hsl(' + activeSidebarColor + ')'
                                  : themeList?.text_color),
                                height: 'inherit',
                                width: 'inherit'
                              }">
                          </svg-icon>
                          <span class="ps-2 f-size-17"
                              *ngIf="isFullSidebar"
                              [ngStyle]="{
                                color: rla.isActive
                                  ? themeList?.hovered_text_color
                                  : themeList?.text_color
                              }">
                            {{'CSR.CSR-LIST' | translate}}
                          </span>
                        </a>
                      </div>
                    </ng-container>
                  </ng-container>
                  <!-- Manage Certificate -->
                  <ng-container *ngIf="manageCertificate">
                    <div routerLinkActive="active"
                        [routerLink]="['/', 'certificate']"
                        #rla="routerLinkActive"
                        [style.--color]="themeList?.hovered_text_color"
                        [style.--bg]="themeList?.hovered_text_color + '20'"
                        *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                        ngbTooltip="{{'SIDEBAR.CERTIFICATE' | translate}}"
                        placement="right">
                      <a class="list-group-item list-group-item-action">
                        <!-- Manage Certificate -->
                        <div [class.select-icon]="rla.isActive"></div>
                        <svg-icon src="assets/svg/icon-menu/certificate.svg"
                            [stretch]="true"
                            [svgStyle]="{
                                'fill': rla.isActive
                                  ? 'hsl(' + activeSidebarColor + ')'
                                  : themeList?.text_color,
                                'stroke': rla.isActive
                                  ? 'hsl(' + activeSidebarColor + ')'
                                  : themeList?.text_color,
                                width: 'inherit'
                            }">
                        </svg-icon>
                        <span class="ps-2 f-size-17"
                            *ngIf="isFullSidebar"
                            [ngStyle]="{
                    color: rla.isActive
                      ? 'hsl(' + activeSidebarColor + ')'
                      : themeList?.text_color
                    }">
                          {{'SIDEBAR.CERTIFICATE'|translate}}
                        </span>
                      </a>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
              <!-- Usage Information -->
              <div class="custom-border"
                  *appCheckPermission="['Admin', 'Master Admin']"
                  [ngStyle]="{'background': usageInformation? '': '' , 'color': themeList?.text_color}">
                <div routerLinkActive="active"
                    #rla="routerLinkActive"
                    class="mt-2 hasSubMenu"
                    *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                    [style.--color]="themeList?.hovered_text_color"
                    [style.--bg]="themeList?.hovered_text_color + '20'"
                    placement="right"
                    ngbTooltip="{{'SIDEBAR.USAGE-INFORMATION'|translate}}"
                    (click)="usageInformation=!usageInformation">
                  <a class="list-group-item list-group-item-action position-relative">
                    <div [class.select-icon]="rla.isActive"></div>
                    <svg-icon src="assets/svg/icon-menu/carbon_information.svg"
                        class="mt-title-svg"
                        [stretch]="true"
                        [svgStyle]="{
                          'fill': rla?.isActive
                            ? 'hsl(' + activeSidebarColor + ')'
                            : themeList?.text_color,
                          height: 'inherit',
                          width: 'inherit'
                        }">
                    </svg-icon>
                    <span class="w-100 f-size-17 pt-1 ps-2"
                        *ngIf="isFullSidebar"
                        [ngStyle]="{'color': themeList?.text_color}">{{"SIDEBAR.USAGE-INFORMATION"|translate}}</span>
                    <div class="text-end ps-2 position-absolute"
                        [ngStyle]="{'color': themeList?.text_color}"
                        [ngClass]="isFullSidebar? 'position-arrow' : 'position-arrow-hidden'">
                      <i class="fal"
                          [ngClass]=" usageInformation ? 'fa-angle-up' : 'fa-angle-down'"></i>
                    </div>
                  </a>
                </div>
                <!-- usage information -->
                <ng-container *ngIf="usageInformation">
                  <!-- Dashboard -->
                  <div routerLinkActive="active"
                      [routerLink]="['/', 'kpi-dashboard']"
                      #rla="routerLinkActive"
                      [style.--color]="themeList?.hovered_text_color"
                      [style.--bg]="themeList?.hovered_text_color + '20'"
                      *appCheckPermission="['Admin', 'Master Admin']"
                      ngbTooltip="{{'SIDEBAR.DASHBOARD' | translate}}"
                      placement="right">
                    <a class="list-group-item list-group-item-action">
                      <div [class.select-icon]="rla.isActive"></div>
                      <svg-icon src="assets/svg/icon-menu/dashboard.svg"
                          [stretch]="true"
                          [svgStyle]="{
                            'fill': rla.isActive
                              ? 'hsl(' + activeSidebarColor + ')'
                              : themeList?.text_color,
                            height: 'inherit',
                            width: 'inherit'
                          }">
                      </svg-icon>
                      <span class="ps-2 f-size-17"
                          *ngIf="isFullSidebar"
                          [ngStyle]="{
                            color: rla.isActive
                              ? 'hsl(' + activeSidebarColor + ')'
                              : themeList?.text_color
                          }">
                        {{'SIDEBAR.DASHBOARD' | translate}}
                      </span>
                    </a>
                  </div>
                  <!-- Usage Dashboard -->
                  <div routerLinkActive="active"
                      [routerLink]="['/', 'usage-dashboard', 'usage']"
                      #rla="routerLinkActive"
                      [style.--color]="themeList?.hovered_text_color"
                      [style.--bg]="themeList?.hovered_text_color + '20'"
                      *appCheckPermission="['Admin', 'Master Admin']"
                      [ngbTooltip]="'SIDEBAR.Package-Usage'|translate"
                      placement="right">
                    <a class="list-group-item list-group-item-action">
                      <div [class.select-icon]="rla.isActive"></div>
                      <svg-icon src="assets/svg/icon-menu/credit-usage.svg"
                          [stretch]="true"
                          [svgStyle]="{
                            'fill': rla.isActive
                              ? 'hsl(' + activeSidebarColor + ')'
                              : themeList?.text_color,
                            height: 'inherit',
                            width: 'inherit'
                          }">
                      </svg-icon>
                      <span class="ps-2 f-size-17"
                          *ngIf="isFullSidebar"
                          [ngStyle]="{
                          color: rla.isActive
                            ? 'hsl(' + activeSidebarColor + ')'
                            : themeList?.text_color
                        }">
                        {{'SIDEBAR.Package-Usage'|translate}}
                      </span>
                    </a>
                  </div>
                  <!-- Memo Report -->
                  <div routerLinkActive="active"
                      [routerLink]="['/', 'memo-report']"
                      #rla="routerLinkActive"
                      [style.--color]="themeList?.hovered_text_color"
                      [style.--bg]="themeList?.hovered_text_color + '20'"
                      *appCheckPermission="['Admin', 'Master Admin']"
                      ngbTooltip="{{'SIDEBAR.MEMO-REPORT' | translate}}"
                      placement="right">
                    <a class="list-group-item list-group-item-action">
                      <svg-icon src="assets/svg/icon-menu/text-link--analysis.svg"
                          [stretch]="true"
                          [svgStyle]="{
                            'fill': rla.isActive
                              ? 'hsl(' + activeSidebarColor + ')'
                              : themeList?.text_color,
                            height: 'inherit',
                            width: 'inherit'
                          }">
                      </svg-icon>
                      <span class="ps-2 f-size-17"
                          *ngIf="isFullSidebar"
                          [ngStyle]="{
                            color: rla.isActive
                              ? 'hsl(' + activeSidebarColor + ')'
                              : themeList?.text_color
                          }">
                        {{'SIDEBAR.MEMO-REPORT' | translate}}
                      </span>
                    </a>
                  </div>
                  <!-- Operation Log -->
                  <div routerLinkActive="active"
                      [routerLink]="['/', 'operation-log']"
                      #rla="routerLinkActive"
                      [style.--color]="themeList?.hovered_text_color"
                      [style.--bg]="themeList?.hovered_text_color + '20'"
                      *appCheckPermission="['Admin', 'Master Admin']"
                      ngbTooltip="{{'SIDEBAR.OPERATION-LOG' | translate}}"
                      placement="right">
                    <a class="list-group-item list-group-item-action">
                      <div [class.select-icon]="rla.isActive"></div>
                      <svg-icon src="assets/svg/icon-menu/task--view.svg"
                          [stretch]="true"
                          [svgStyle]="{
                            'fill': rla.isActive
                              ? 'hsl(' + activeSidebarColor + ')'
                              : themeList?.text_color,
                            height: 'inherit',
                            width: 'inherit'
                          }">
                      </svg-icon>
                      <span class="ps-2 f-size-17"
                          *ngIf="isFullSidebar"
                          [ngStyle]="{
                            color: rla.isActive
                              ? 'hsl(' + activeSidebarColor + ')'
                              : themeList?.text_color
                          }">
                        {{'SIDEBAR.OPERATION-LOG' | translate}}
                      </span>
                    </a>
                  </div>
                </ng-container>
              </div>
              <!-- Memo -->
              <div routerLinkActive="active"
                  [routerLink]="['/', 'memos']"
                  #rla="routerLinkActive"
                  *appCheckPermission="['General']"
                  ngbTooltip="{{'SIDEBAR.MEMOS' | translate}}"
                  placement="right">
                <a class="list-group-item list-group-item-action notification">
                  <!-- Manage Users -->
                  <div [ngClass]="rla.isActive ? 'select-icon' : '' "></div>
                  <svg-icon src="assets/images/icons/files/file-earmark-lines-copy.svg"
                      [stretch]="true"
                      [svgStyle]="{
                            height: 'inherit',
                            'stroke': rla.isActive
                              ? 'hsl(' + activeSidebarColor + ')'
                              : themeList?.text_color,
                            width: 'inherit'
                          }">
                  </svg-icon>
                  <span class="ps-2 f-size-17 text-truncate-clip"
                      *ngIf="isFullSidebar"
                      [ngStyle]="{
                        color: rla.isActive
                          ? 'hsl(' + activeSidebarColor + ')'
                          : themeList?.text_color
                      }">
                    {{'MEMOS.MEMO-LIST' | translate}}
                  </span>
                  <span class="d-inline-block"
                      [ngClass]="isFullSidebar ? 'badge-number-memo': 'd-none'">
                    {{ badge()['allMemo'] }}
                  </span>
                </a>
              </div>
              <!-- folder -->
              <div routerLinkActive="active"
                  [routerLink]="['/', 'folders']"
                  #rla="routerLinkActive"
                  [style.--bg]="themeList?.hovered_text_color + '20'"
                  *appCheckPermission="['General']"
                  ngbTooltip="{{'SIDEBAR.FOLDER' | translate}}"
                  placement="right">
                <a class="list-group-item list-group-item-action notification">
                  <!-- Manage Users -->
                  <div [ngClass]="rla.isActive ? 'select-icon' : '' "></div>
                  <svg-icon src="assets/svg/icon-menu/folder.svg"
                      [stretch]="true"
                      [svgStyle]="{
                        height: 'inherit',
                        'stroke': rla.isActive
                          ? 'hsl(' + activeSidebarColor + ')'
                          : themeList?.text_color,
                        width: 'inherit'
                      }">
                  </svg-icon>
                  <span class="ps-2 f-size-17 text-truncate-clip"
                      *ngIf="isFullSidebar"
                      [ngStyle]="{
                        color: rla.isActive
                          ? 'hsl(' + activeSidebarColor + ')'
                          : themeList?.text_color
                      }">
                    {{'SIDEBAR.FOLDER' | translate}}
                  </span>
                </a>
              </div>
              <!-- Config -->
              <div class="d-none"
                  routerLinkActive="active"
                  [routerLink]="['/', 'configuration']"
                  #rla="routerLinkActive"
                  [style.--color]="themeList?.hovered_text_color"
                  [style.--bg]="themeList?.hovered_text_color + '20'"
                  *appCheckPermission="['Admin', 'Master Admin']"
                  ngbTooltip="{{'SIDEBAR.CONFIGURATION' | translate}}"
                  placement="right">
                <a class="list-group-item list-group-item-action">
                  <div [ngClass]="rla.isActive ? 'select-icon' : '' "></div>
                  <svg-icon src="assets/svg/icon-menu/setting-theme.svg"
                      [stretch]="true"
                      [svgStyle]="{
                    'fill': rla.isActive
                      ? themeList?.hovered_text_color
                      : themeList?.text_color,
                    height: 'inherit',
                    width: 'inherit'
                  }">
                  </svg-icon>
                  <span class="ps-2 f-size-17"
                      *ngIf="isFullSidebar"
                      [ngStyle]="{
                    color: rla.isActive
                      ? themeList?.hovered_text_color
                      : themeList?.text_color
                  }">
                    {{'SIDEBAR.CONFIGURATION' | translate}}
                  </span>
                </a>
              </div>
            </div>
            <div class="custom-border-top d-md-none d-block"
                ngbTooltip="{{'SIDEBAR.LOGOUT' | translate}}"
                [style.--color]="themeList?.hovered_text_color"
                (click)="logOut()">
              <a class="list-group-item list-group-item-action">
                <div [ngClass]="rla.isActive ? 'select-icon' : '' "></div>
                <svg-icon src="assets/svg/icon-menu/logout.svg"
                    [stretch]="true"
                    [svgStyle]="{
                        'stroke': rla.isActive
                          ? themeList?.hovered_text_color
                          : themeList?.text_color,
                        height: 'inherit',
                        width: 'inherit',
                        'margin-left.px': -2,
                      }">
                </svg-icon>
                <span class="ps-base-l7 f-size-17 text-truncate-clip"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.LOGOUT' | translate}}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- Page Content -->
      <div class="container-page position-relative flex-fill"
          #pageSizeElement>
        <ng-container *appFeatureFlag="'ddoc'">
          <ng-container *ngIf="showWarning &&
              profile?.otp_type === null &&
              (checkRouteActive('/pending-approval') ||
                checkRouteActive('/news-feed') ||
                checkRouteActive('/memos'))">
            <div class="box-warning d-flex w-100">
              <i class="fal fa-exclamation-triangle align-self-center me-2"
                  [style.color]="'rgb(216, 187, 24)'">
              </i>
              <span class="align-self-center">{{ 'CERTIFICATE.OTP-NOT-ENABLE'|translate }}</span>
              <button class="btn align-self-center ms-3"
                  [routerLink]="['/', 'profile-detail', 'otp']"
                  appSetTheme
                  type="button">
                <span class=f-13>{{'CERTIFICATE.GOTO-OTP-SETTINGS'|translate}}</span>
              </button>
              <a class="text-black p-2 align-self-center ms-auto"
                  href="javascript:void(0)"
                  role="button"
                  (click)="showWarning = false">
                <i class="fal fa-times"></i>
              </a>
            </div>
          </ng-container>
        </ng-container>
        <app-modal-pdf-preview-std4 *ngIf="isPreview">
        </app-modal-pdf-preview-std4>
        <div *ngIf="openSidebar"
            (click)="openSidebar = !openSidebar"
            class="background-blur"></div>
        <div #pageContainer
            class="container"
            appScrollTracker
            (scrollingFinished)="containerScrollingFinished()">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>