import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SIGNATURE_COLOR_LIST } from '../../../modules/memos/service/upload-memo.constants';
import { base64ToFile } from '../../../core/services/utils.service';
// import { CanvasWhiteboardComponent } from '@preeco-privacy/ng2-canvas-whiteboard';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { AlertService } from '../../../core/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ThemeList } from '../../service/theme.service';
import { OtpModalComponent } from '../otp-modal/otp-modal.component';
import { TextSignatureComponent } from '../../../modules/profile/component/text-signature/text-signature.component';
import { ProfileService } from '../../../modules/profile/shared/profile.service';
import { buildFullName } from '../../utils/common.util';
import { Store } from '@ngxs/store';
import {
  CropperPosition,
  Dimensions,
  ImageCropperComponent,
} from 'ngx-image-cropper';
import { ImageProcessingService } from '../../service/image-processing.service';
import { CanvasWhiteboardComponent } from '@preeco-privacy/ng2-canvas-whiteboard';
import * as _ from 'lodash';

@Component({
  selector: 'app-select-sign-method-modal',
  templateUrl: './select-sign-method-modal.component.html',
  styleUrls: ['./select-sign-method-modal.component.scss'],
})
export class SelectSignMethodModalComponent {
  /** Enable typing signature text */
  @Input() customTextSignature = false;
  @Input() enableUploadSignature = false;
  @Input() enableUsingStoredSignature = true;
  @Input() enableTextSignature = false;
  @Input() isSaved: boolean;
  @Input() otpEnable: boolean;
  @Input() isNeedSign: boolean;
  @Input() isNoneUser = false;
  @Input() themeList?: ThemeList;
  @Input() header: any;
  @Input() numberOfSignature: number | any = null;
  @Input() countPage: number;

  @Input() signatureText: string;
  @Input() otp: string;
  @Output() otpChange = new EventEmitter<string>();

  @Input() signature: File;
  @Output() signatureChange = new EventEmitter<File>();

  @Input() signaturePreview: string;
  @Input() canSignNow = true;
  /** Set the signature thickness on canvas.
   * When set it the responsive thickness is not used
   */
  @Input() signatureThickness: number;
  @Output() confirm = new EventEmitter();
  @Output() closeModal = new EventEmitter();

  @Input() isUseStored: boolean;
  @Output() isUseStoredChange = new EventEmitter();

  @ViewChild('canvasWhiteboard', { static: false })
  canvasWhiteboard: CanvasWhiteboardComponent;

  @ViewChild('selectSignMethodModal', { static: false })
  selectSignMethodModal: ElementRef;
  @ViewChild('signSignature', { static: false })
  signSignature: ElementRef;
  @ViewChild('uploadUserSignature', { static: false })
  uploadUserSignature: ElementRef;

  @ViewChild('uploadFileSignature', { static: false })
  uploadFileSignatureRef: ElementRef;
  @ViewChild(OtpModalComponent)
  otpModalComponent: OtpModalComponent;
  @ViewChild('imageCropper')
  imageCropperComponent: ImageCropperComponent;
  @ViewChild('signEditor', { static: false })
  signEditor: ElementRef;

  @Input() isUploadOther = false;

  // Sign now
  signImageBlob: any = null;
  blobOutput: any = '';
  croppedImage: any = '';
  imageChangedEvent: any = '';
  errorMsg: any = '';
  fileInput: any = '';
  signatureColorList = SIGNATURE_COLOR_LIST;
  selectedColor = this.signatureColorList[0];
  showColorPicker = false;
  isSignNow = false;
  isUpload = false;
  uploadOriginalFileName = '';

  selectSignMethodModalRef: NgbModalRef;
  modal: NgbModalRef;
  showPopupLine = false;
  isShowNewSign = false;
  cropper: CropperPosition = {
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
  };

  constructor(
    private profileService: ProfileService,
    public modalService: NgbModal,
    private alert: AlertService,
    public translate: TranslateService,
    private store: Store,
    private imageProcessingService: ImageProcessingService,
  ) {}

  /**
   * Calculate signature thickness from the width of canvas.
   * This calculation base on A4 paper (H.297mm x W.210mm).
   * Typically the signature canvas width is about 17.5% of paper (36.75 mm).
   * The common pen ballpoint is 0.5mm.
   * So, a signature thickness is about 1.58% (0.5 / 31.5) of the signature canvas width.
   * @param canvasWidth The width of canvas of signature
   * @returns The thickness of the signature
   */
  calcSignatureThickness(canvasWidth: number): number {
    return canvasWidth * (0.5 / 36.75);
  }

  // TODO - There is the unused parameter
  getSignatureThickness(canvasWidth: number): number {
    return this.signatureThickness || 10;
  }

  openOtpModal(): void {
    this.modalService.dismissAll();
    this.otpModalComponent.openModal();
  }

  openSelectSignMethodModal(center = true): void {
    this.selectSignMethodModalRef = this.modalService.open(
      this.selectSignMethodModal,
      {
        backdrop: 'static',
        size: 'size-smax56',
        centered: center,
      },
    );
  }

  openModalUploadFileSignature(content: any): void {
    this.modalService.dismissAll();
    this.selectSignMethodModalRef = this.modalService.open(
      this.uploadFileSignatureRef,
      {
        backdrop: 'static',
        size: 'size-smax56',
        centered: true,
      },
    );
  }

  openSignModal(): void {
    if (this.selectSignMethodModalRef) {
      this.selectSignMethodModalRef.dismiss('Cross Click');
    }
    const signModal = this.modalService.open(this.signSignature, {
      backdrop: 'static',
      keyboard: false,
      size: 'size-smax56',
      centered: true,
    });
    signModal.result.finally(() => (this.isSignNow = false));
  }

  openUploadUserSignature(event: any): void {
    this.isUpload = true;
    this.signImageBlob = event.target.files[0];
    this.uploadOriginalFileName = this.signImageBlob.name;
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {};
    this.openSignModal();
  }

  onClearCanvas(): void {
    this.canvasWhiteboard.clearCanvas();
  }

  onUndoCanvas(): void {
    this.canvasWhiteboard.undoLocal();
  }

  onRedoCanvas(): void {
    this.canvasWhiteboard.redoLocal();
  }

  onClickColorPicker(): void {
    this.showColorPicker = !this.showColorPicker;
  }

  onSaveCanvas(): void {
    this.isShowNewSign = true;
    this.canvasWhiteboard.saveLocal();
  }

  onSelectedColor(color: string): void {
    this.selectedColor = color;
  }

  onSave(event: any): void {
    this.canvasWhiteboard.generateCanvasBlob((blob: any) => {
      this.blobOutput = blob;
      this.signImageBlob = blob;
      this.isSignNow = false;
    });
  }

  onSignNowClick(): void {
    this.isUseStoredChange.emit(false);
    this.signatureThickness = 10;
    this.isSignNow = true;
    this.uploadOriginalFileName = 'sign_now.png';
    this.openSignModal();
  }

  onTextSignatureClick(): void {
    if (this.selectSignMethodModalRef) {
      this.selectSignMethodModalRef.dismiss();
    }
    const profile = this.store.selectSnapshot((state) => state.auth);
    const modal = this.modalService.open(TextSignatureComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    modal.componentInstance.selectOnly = !this.customTextSignature;
    modal.componentInstance.isModal = true;
    modal.componentInstance.fullName =
      this.signatureText ||
      buildFullName(profile.first_name, profile.last_name) ||
      '';
    const cancelClick = new EventEmitter();
    cancelClick.subscribe({
      next: () => this.reOpenSelectSignModal(),
    });
    const submitClick = new EventEmitter();
    submitClick.subscribe({
      next: (res: any) => modal.close(res),
    });
    modal.componentInstance.cancelClick = cancelClick;
    modal.componentInstance.signatureSaveClick = submitClick;
    modal.result
      .then((res) => {
        this.signatureChange.emit(res);
        if (this.otpEnable) {
          this.openOtpModal();
          return;
        }
        this.submit();
      })
      .catch(() => {});
  }

  imageCropped(output: any): void {
    this.croppedImage = output.base64;
    this.blobOutput = base64ToFile(
      output.base64,
      this.imageChangedEvent.target?.files[0]?.name,
    );
  }

  imageLoaded(): void {
    // show cropper
  }

  loadImageFailed(): void {
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.errorMsg = 'APPROVAL.ERROR-UNABLE-UPLOAD';
  }

  fileChangeEvent(file: any): void {
    this.signImageBlob = file.target.files[0];
    this.errorMsg = '';
    this.imageChangedEvent = file;
    this.fileInput = file.target.files[0];
    this.uploadOriginalFileName = this.fileInput?.name;
  }

  resetData(): void {
    this.isUpload = false;
    this.uploadOriginalFileName = '';
    this.isSignNow = false;
    this.signImageBlob = null;
    this.blobOutput = '';
    this.croppedImage = '';
    this.imageChangedEvent = '';
    this.errorMsg = '';
    this.fileInput = '';
  }

  clearSignature(): void {
    this.alert
      .confirm(this.translate.instant('ALERT.Are you sure'), ' ')
      .then((res) => {
        if (res.value) {
          this.reOpenSelectSignModal();
          this.isShowNewSign = false;
        }
      });
  }

  changeComplete(event: any): void {
    this.selectedColor = event.color.hex;
  }

  saveSignature(): void {
    this.signature = new File([this.blobOutput], 'online-sign.png', {
      type: this.blobOutput.type,
    });
    this.signatureChange.emit(this.signature);

    if (this.otpEnable) {
      this.openOtpModal();
    } else {
      this.submit();
    }
  }

  filePhotoChangeEvent(event: any): void {
    this.isUpload = true;
    this.errorMsg = '';
    this.signImageBlob = event.target.files[0];
    this.uploadOriginalFileName = this.signImageBlob.name;
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {};
    this.openSignModal();
    // Clear input file to avoid error when upload same file again
    event.target.value = '';
  }

  submit(useStoredSignature = false): void {
    this.isUseStoredChange.emit(useStoredSignature);
    this.confirm.emit(this.uploadOriginalFileName);
    this.resetData();
  }

  close(): void {
    this.modalService.dismissAll();
    this.closeModal.emit();
    this.resetData();
  }

  reOpenSelectSignModal(): void {
    this.modalService.dismissAll();
    this.resetData();
    this.openSelectSignMethodModal(true);
  }

  verifyOTP(): void {
    if (this.otpModalComponent.invalidOTP) {
      this.alert.error('Input OTP');
      return;
    }

    this.otpChange.emit(this.otp);
    this.submit();
  }

  onEditSizeLine(): void {
    this.showPopupLine = !this.showPopupLine;
  }

  resizeLine(event: any): void {
    this.getSignatureThickness(this.signatureThickness);
  }

  resetSizeLine(): void {
    this.signatureThickness = 10;
  }

  async onCropperReady(dimensions: Dimensions) {
    if (this.isUpload) {
      return;
    }
    if (!this.imageCropperComponent.imageFile) {
      throw new Error(
        `There is no 'imageFile. Please check the value of imageFile from 'image-cropper'`,
      );
    }
    this.cropper =
      await this.imageProcessingService.getSignatureBoundary(
        this.imageCropperComponent.imageFile,
      );
  }

  closeTool(): void {
    this.showColorPicker = false;
    this.showPopupLine = false;
  }

  newSignNow() {
    this.isSignNow = true;
    this.isShowNewSign = false;
  }
}
