<ng-template #signSignature
    let-c="close"
    let-d="dismiss">
  <div class="modal-header">
    <div class="col p-0">
      <h4 class="mb-0">
        {{ isSignNow ? ('APPROVAL.SIGN-SIGNATURE' | translate) : ('APPROVAL.CONFIRM-SIGNATURE' | translate) }}
      </h4>
    </div>
    <div class="col-auto p-0">
      <svg-icon src="assets/images/icons/closeX.svg"
          [svgStyle]="{ 'width.px': 22, 'height.px': 22 }"
          class="pointer"
          (click)="close()"></svg-icon>
    </div>
  </div>
  <div class="modal-body p-4">
    <ng-container *ngTemplateOutlet="isSignNow ? signEditor : signSignatureDetail">
    </ng-container>

    <div [ngClass]="{'d-none': !signImageBlob}">
      <div class="justify-content-center"
          style="position: relative">
        <div class="message-box"><span class="message-error">{{ errorMsg | translate }}</span></div>
        <div *ngIf="!isSignNow"
            class="d-flex justify-content-end mt-4"
            [style.--c]="themeList?.header_table_color"
            [style.--bg]="'#fff'">
          <button type="button"
              class="btn btn-std btn-h btn-std-2ry fs-base-l4 fw-semibold mx-1 py-base-l8 px-base-l4 btn-width"
              *ngIf="!isUploadOther"
              (click)="isUpload ? reOpenSelectSignModal() : clearSignature()">{{ "MEMOS.CANCEL" | translate}}</button>
          <button type="button"
              class="btn btn-std btn-h btn-std-2ry fs-base-l4 fw-semibold mx-1 py-base-l8 px-base-l4 btn-width"
              *ngIf="isUploadOther"
              (click)="close()">{{ "MEMOS.CANCEL" | translate}}
          </button>

          <button type="button"
              class="btn btn-std btn-h btn-std-prim fs-base-l4 mx-1 py-base-l8"
              (click)="saveSignature()">{{ "MEMOS.CONFIRM" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #signEditor>
  <div class="row">
    <div class="col m-t-20 m-b-20">
      <div class="signature-sign-container"
          [style.--bg]="'#658FE2'">
        <div class="d-flex justify-content-between">
          <div class="pt-2">
            {{'MEMOS.PLEASE-SIGN-SIGNATURE' | translate}}
          </div>
          <div class="signature-sign-options"
              (clickOutside)="closeTool()">
            <button class="tools-btn"
                ngbTooltip="{{'MEMOS.Nib Size' | translate}}"
                tooltipClass="tooltip-width-85px"
                (click)="onEditSizeLine()">
              <svg-icon src="assets/images/icons/editing/pen.svg"
                  [svgStyle]="{ 'width.px': 20, 'height.px': 20, 'stroke': '#1958D3' }"
                  class="pointer"></svg-icon>
            </button>
            <button class="tools-btn"
                ngbTooltip="{{'MEMOS.COLOR' | translate}}"
                (click)="onClickColorPicker()">
              <svg-icon src="assets/images/icons/color-palette.svg"
                  [svgStyle]="{ 'width.px': 20, 'height.px': 20, 'stroke': '#1958D3' }"
                  class="pointer"></svg-icon>
            </button>
            <button class="tools-btn"
                ngbTooltip="{{'MEMOS.UNDO' | translate}}"
                (click)="onUndoCanvas()">
              <svg-icon src="assets/images/icons/reset.svg"
                  [svgStyle]="{ 'width.px': 20, 'height.px': 20, 'stroke': '#1958D3' }"
                  class="pointer"></svg-icon>
            </button>
            <button class="tools-btn"
                ngbTooltip="{{'MEMOS.REDO' | translate}}"
                (click)="onRedoCanvas()">
              <svg-icon src="assets/images/icons/redo.svg"
                  [svgStyle]="{ 'width.px': 20, 'height.px': 20, 'stroke': '#1958D3' }"
                  class="pointer"></svg-icon>
            </button>
            <button class="tools-btn"
                ngbTooltip="{{'MEMOS.DELETE' | translate}}"
                (click)="onClearCanvas()">
              <svg-icon src="assets/images/icons/editing/trash.svg"
                  [svgStyle]="{ 'width.px': 20, 'height.px': 20, 'stroke': '#1958D3' }"
                  class="pointer"></svg-icon>
            </button>
            <div *ngIf="showPopupLine"
                class="resize-popup-window">
              <div class="d-flex">
                <input type="range"
                    min="1"
                    max="30"
                    [(ngModel)]="signatureThickness"
                    (change)="resizeLine($event)"
                    class="slider"
                    [ngStyle]="{'accent-color': themeList?.header_table_color}">
                <span class="ms-2">{{signatureThickness}}</span>
              </div>
              <div class="text-end">
                <span class="text-asterisk pointer"
                    (click)="resetSizeLine()">reset</span>
              </div>
            </div>
            <div *ngIf="showColorPicker"
                class="color-popup-window">
              <!-- default color list-->
              <div class="color-list">
                <span class="color-item-border"
                    *ngFor="let color of signatureColorList"
                    [style.border-color]="color === selectedColor ? color : 'white'"
                    (click)="onSelectedColor(color)">
                  <span class="color-item"
                      [style.background]="color"></span>
                </span>
              </div>
              <!-- custom color-->
              <div class="color-custom">
                <color-sketch (onChangeComplete)="changeComplete($event)"></color-sketch>
              </div>
            </div>
          </div>
        </div>
        <div #canvasContainer
            class="signature-sign-wrap"
            style="height: 300px;">
          <canvas-whiteboard #canvasWhiteboard
              [clearButtonEnabled]="false"
              [drawButtonEnabled]="false"
              [drawingEnabled]="true"
              [undoButtonEnabled]="false"
              [redoButtonEnabled]="false"
              [colorPickerEnabled]="false"
              [saveDataButtonEnabled]="false"
              [shapeSelectorEnabled]="false"
              [lineWidth]="getSignatureThickness(canvasContainer.offsetWidth)"
              [startingColor]="'transparent'"
              [shouldDownloadDrawing]="false"
              [strokeColor]="selectedColor"
              (onSave)="onSave($event)"
              (mouseover)="closeTool()">
          </canvas-whiteboard>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-end mt-4"
      [style.--c]="themeList?.header_table_color"
      [style.--bg]="'#fff'">
    <button type="button"
        class="btn btn-std btn-h btn-std-2ry fs-base-l4 fw-semibold mx-1 py-base-l8 px-base-l4 btn-width"
        (click)="reOpenSelectSignModal()">{{ "MEMOS.CANCEL" | translate }}</button>
    <button type="button"
        class="btn btn-std btn-h btn-std-prim fs-base-l4 mx-1 py-base-l8"
        (click)="onSaveCanvas()">{{ "CREATE-USER.CONTINUE" | translate }}</button>
  </div>
</ng-template>

<ng-template #signSignatureDetail>
  <div *ngIf="signImageBlob">
    <h6 class="d-inline-block">{{"APPROVAL.SELECT-SIGNATURE-SCOPE"|translate}}</h6>
    <div class="row">
      <div class="col align-self-center text-center position-relative">
        <img class="img-fluid img-thumbnail"
            *ngIf="!signImageBlob"
            src="assets/images/original.png">
        <image-cropper *ngIf="signImageBlob"
            [imageFile]="signImageBlob"
            [imageChangedEvent]="signImageBlob"
            [maintainAspectRatio]="false"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (loadImageFailed)="loadImageFailed()"
            [cropper]="cropper"
            (cropperReady)="onCropperReady($event)"
            format="png"
            outputType="both"
            output="base64"
            class="img-thumbnail"
            [style.height]="'300px'"
            [style.--bg]="'#658FE2'"
            #imageCropper>
        </image-cropper>
        <div *ngIf="isShowNewSign"
            class="file-input position-absolute pointer"
            [style.bottom.px]="15"
            [style.left.px]="25"
            (click)="newSignNow()">
          <span class="text-gray-neutral btn-new-sign">
            {{'SELECT-SIGN-METHOD.New Sign' | translate}}
          </span>
        </div>
      </div>
      <small class="mt-2 text-red-pig-blood fs-base-l7">
        {{"CERTIFICATE.For JPG file the system will automatically remove background" | translate}}
      </small>
    </div>
  </div>
</ng-template>

<ng-template #selectSignMethodModal
    let-c="close"
    let-d="dismiss"
    class="modal-dialog"
    let-modal>
  <div class="modal-header">
    <h4 class="flex-grow-1 fs-base-u5 fw-bold mb-0">
      {{"SELECT-SIGN-METHOD.QUESTION" | translate}}
    </h4>
    <div class="col-auto p-0">
      <svg-icon src="assets/images/icons/closeX.svg"
          class="pointer"
          [svgStyle]="{ 'width.px': 22, 'height.px': 22 }"
          (click)="close()"></svg-icon>
    </div>
  </div>
  <div class="modal-body py-3">
    <p *ngIf="numberOfSignature"
        class="text-center numbers-of-signature">
      {{('SELECT-SIGN-METHOD.You are about to sign $numberOfSignature of $countPage pages.'|translate)
      .replaceAll('$numberOfSignature', numberOfSignature)
      .replaceAll('$countPage', countPage)}}
    </p>
    <div class="row btn-container"
        [style.--c]="themeList?.header_table_color"
        [style.--bg]="'#fff'">
      <div *ngIf="enableTextSignature"
          class="col-12 col-md my-2"
          [ngClass]="[enableTextSignature ? 'col-md-6' : 'col-md']">
        <button class="btn btn-outline shadow-none d-flex align-items-center justify-content-center mb-3 flex-column"
            type="button"
            [disabled]="!enableTextSignature"
            (click)="onTextSignatureClick()">
          <div class="icon-border">
            <svg stroke="currentColor"
                height="24"
                width="24">
              <use height="24"
                  width="24"
                  href="assets/images/icons/documents/text.svg#text">
              </use>
            </svg>
          </div>
          <span class="d-inline-block mt-3 fw-medium">
            {{"SELECT-SIGN-METHOD.SIGN-BY-TYPING" | translate}}
          </span>
        </button>
      </div>
      <div class="col-12 col-md my-2"
          *ngIf="!isNoneUser"
          [ngClass]="[enableTextSignature ? 'col-md-6' : 'col-md']">
        <button class="btn btn-outline shadow-none d-flex align-items-center justify-content-center mb-3 flex-column"
            type="button"
            [disabled]="!enableUsingStoredSignature"
            (click)="otpEnable ? openOtpModal() : submit(true)">
          <div class="icon-border">
            <svg stroke="currentColor"
                height="24"
                width="24">
              <use href="assets/images/icons/sign-saved.svg#sign-saved"
                  height="24"
                  width="24">
              </use>
            </svg>
          </div>
          <span class="d-inline-block mt-3 fw-medium">{{"SELECT-SIGN-METHOD.STORED" | translate}}</span>
        </button>
      </div>

      <div class="col-12 col-md my-2"
          [ngClass]="[enableTextSignature ? 'col-md-6' : 'col-md']">
        <button class="btn btn-outline shadow-none d-flex align-items-center justify-content-center mb-3 flex-column"
            type="button"
            [disabled]="!enableUploadSignature"
            (click)="openModalUploadFileSignature(uploadFileSignature)">
          <div class="icon-border">
            <svg stroke="currentColor"
                height="24"
                width="24">
              <use height="24"
                  width="24"
                  href="assets/images/icons/documents/upload.svg#upload">
              </use>
            </svg>
          </div>
          <span class="d-inline-block mt-3 fw-medium">{{"SELECT-SIGN-METHOD.UPLOAD" | translate}}</span>
        </button>
      </div>

      <div class="col-12 col-md my-2"
          [ngClass]="[enableTextSignature ? 'col-md-6' : 'col-md']">
        <button class="btn btn-outline shadow-none d-flex align-items-center justify-content-center mb-3 flex-column"
            type="button"
            (click)="onSignNowClick()">
          <div class="icon-border">
            <svg stroke="currentColor"
                height="24"
                width="24">
              <use height="24"
                  width="24"
                  href="assets/images/icons/editing/pen.svg#pen">
              </use>
            </svg>
          </div>
          <span class="d-inline-block mt-3 fw-medium">{{"SELECT-SIGN-METHOD.SIGN-NOW" | translate}}</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #uploadFileSignature>
  <app-modal-confirmation-std4-skin [dismissText]="'COMMON.Cancel' | translate"
      [header]="'UPLOAD.Upload file' | translate | titlecase"
      [title]="'Detail'"
      [submitText]="'COMMON.Save' | translate"
      [bodyNotDesignDownload]="true"
      (dismiss)="reOpenSelectSignModal()"
      (submitted)="selectSignMethodModalRef.close()">
    <ng-template>
      <div>
        <label>{{'MEMOS.DETAIL' | translate}}</label>
        <ngx-file-drop class="file-drop pointer"
            dropZoneLabel="Browse or Drop files here"
            (click)="filePhoto.click()">
          <ng-template ngx-file-drop-content-tmp
              let-openFileSelector="openFileSelector">
            <div class="d-flex flex-column align-items-center gap-1">
              <svg class="pointer icon-btn-svg ">
                <use href="assets/images/icons/apps/window-arrow-up.svg#window-arrow-up"
                    stroke="#707485"
                    height="20"
                    width="20">
                </use>
              </svg>
              <div>
                <span class="fw-bold fs-base-l2 text-blue-tang ps-2 my-1">
                  {{ 'MEMOS.Choose a file or drag & drop it here' | translate}}
                </span>
              </div>
              <div class="fs-base-l4 text-red-scarlet px-3 text-center">
                {{'MEMOS.The maximum size limit per file is 25 MB and the total files size limit is 45 MB' |translate }}
              </div>
              <div class="my-1">
                <button
                    class="btn btn-std btn-h btn-std-2ry fs-base-l4 fw-semibold mx-1 py-base-l8 px-base-l4 btn-width"
                    type="button">
                  {{'MEMOS.Browse-File' |translate}}
                </button>
              </div>
            </div>
          </ng-template>
        </ngx-file-drop>
      </div>
    </ng-template>

  </app-modal-confirmation-std4-skin>
</ng-template>

<ng-template #uploadUserSignature
    let-c="close"
    let-d="dismiss"
    class="modal-dialog">
  <div class="modal-header align-items-center">
    <div class="col">
      <h4 class="mb-1">{{"CREATE-USER.EDIT-SIGNATURE" | translate}}</h4>
    </div>
    <div class="col-auto">
      <i class="fas fa-times pointer size-24px"
          data-dismiss="modal"
          (click)="d('Cross click')"></i>
    </div>
  </div>
  <div class="modal-body text-center"
      *ngIf="signaturePreview">
    <img [src]="signaturePreview | secure | async"
        class="img-fluid img-thumbnail blend-hard-light">
    <h6 class="mt-2 fw-bold">{{"CREATE-USER.CURRENT-SIGNATURE"|translate}}</h6>
  </div>
  <div class="modal-footer"
      style="position: relative">
    <button type="button"
        class="btn btn-outline-secondary gray"
        (click)="filePhoto.click()">
      {{"CREATE-USER.UPLOAD" |translate}}</button>
    <button type="button"
        class="btn btn-outline-secondary"
        *ngIf="canSignNow"
        (click)="onSignNowClick()">
      {{"SELECT-SIGN-METHOD.SIGN-NOW" |translate}}</button>
  </div>
</ng-template>

<app-otp-modal [header]="header"
    [(otp)]="otp"
    [isSaved]="isSaved"
    (otpChange)="verifyOTP()"
    (closeModal)="close()"></app-otp-modal>

<input type="file"
    accept="image/png, image/jpeg"
    class="d-none"
    (change)="filePhotoChangeEvent($event)"
    #filePhoto>