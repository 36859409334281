<div class="font-charmonman d-none">&#8203;</div>
<div class="position-relative"
    [ngStyle]="{'padding': isModal? '0': '3rem'}">
  <div class="modal-header d-flex justify-content-between">
    <h4 class="fs-base-u5 fw-bold">
      {{ 'SELECT-SIGN-METHOD.SIGN-BY-TYPING' | translate }}
    </h4>
    <svg-icon src="assets/images/icons/closeX.svg"
        *ngIf="isModal"
        [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
        class="pointer"
        (click)="onCancelClick()"></svg-icon>
  </div>
  <div class="p-5">
    <div class="">
      <app-slide-tab-bar [tabs]="tabs"
          [showBadge]="false"
          [selectedTab]="selectedSignature"
          (selectedTabChange)="onSelectedTabChange($event)">
      </app-slide-tab-bar>
      <ng-container [ngSwitch]="selectedSignature">
        <div class="pt-3">
          <label class="m-0 fw-medium text-balck-pantone">{{ 'PROFILE.Full Name Example' | translate }}</label>
          <div class="my-2">
            <input class="form-control"
                [readOnly]="selectOnly"
                [(ngModel)]="_fullName"
                (ngModelChange)="onFullNameChange()">
          </div>
          <label class="d-block mt-3 fw-medium text-balck-pantone">{{'MEMOS.Select type of font' | translate}}</label>
          <div *ngSwitchCase="'full'"
              class="box-select-font-signature">
            <div *ngFor="let fontType of fontOptions; let i = index"
                class="d-flex justify-content-between align-items-center p-3">
              <div class="form-check">
                <input class="form-check-input"
                    type="radio"
                    name="font"
                    [id]="'font-' + i"
                    (click)="onTextSignatureSelect('full', fontType.id)"
                    [checked]="fontType.id === 1">
                <label class="form-check-label"
                    [for]="'font-' + i">
                  {{ fontType.name }}
                </label>
              </div>
              <canvas class="canvas-signature col-12"
                  [style.height.px]="50"
                  [style.width.px]="250"
                  [id]="'canvas-fullname-' + (i+1)">
              </canvas>
            </div>
          </div>
        </div>

        <div *ngSwitchCase="'abbreviate'"
            class="box-select-font-signature">
          <div *ngFor="let fontType of fontOptions; let i=index"
              class="d-flex justify-content-between align-items-center p-3">
            <div class="form-check">
              <input class="form-check-input"
                  type="radio"
                  name="font"
                  [id]="'font-' + i"
                  (click)="onTextSignatureSelect('abbreviate' ,fontType.id)"
                  [checked]="fontType.id === 1">
              <label class="form-check-label"
                  [for]="'font-' + i">
                {{ fontType.name }}
              </label>
            </div>
            <canvas class="canvas-signature col-12"
                [style.height.px]="50"
                [style.width.px]="250"
                [id]="'canvas-abbreviated-name-' + (i+1)">
            </canvas>
          </div>
        </div>
      </ng-container>
      <div class="d-flex justify-content-end p-0">
        <button class="btn btn-std btn-h btn-std-2ry fs-base-l4 fw-semibold mx-1 py-base-l8 px-base-l4 btn-width"
            type="button"
            [ngClass]="{'p-btn': !isModal}"
            (click)="onCancelClick()">
          {{ 'MEMOS.CANCEL' | translate }}
        </button>
        <button type="button"
            class="btn btn-std btn-h btn-std-prim fs-base-l4 mx-1 py-base-l8"
            [ngClass]="{'p-btn': !isModal}"
            (click)="onConfirmClick()">
          {{ 'MEMOS.CONFIRM' | translate }}
        </button>

      </div>
    </div>