<nav #navbarSize
    class="navbar navbar-expand-lg navbar-light custom-layer justify-content-end bg-std-color-1"
    id="navbar"
    [ngStyle]="{'background': 'linear-gradient(90deg, '+themeList?.bg_color_secondary+')'}"
    (window:resize)="onResize($event)">
  <div class="parent-content">
    <ng-content></ng-content>
  </div>
  <div *ngIf="showNotificationToggle"
      class="background-blur">
  </div>
  <div class="col-auto px-0 align-items-center"
      (clickOutside)="closeNotification()">
    <button class="btn btn-notification d-flex"
        type="button"
        (click)="openNotification()">
      <div class="noti-center ">
        <svg-icon [src]="'assets/svg/icon-menu/' + 
            (isNotificationUnread ? 'noti-red.svg' :'noti.svg')"
            [svgStyle]="{
              'stroke': 'hsl(' + activeSidebarColor + ')',
                'height.px': 20,
                'width.px': 20
            }"
            aria-hidden="true"
            (mouseover)="hoverText('notification', true)"
            (mouseleave)="hoverText('notification', false)"
            [ngStyle]="{'color': hoverList.notification? 'hsl(' + activeSidebarColor + ')': themeList?.text_color}">
        </svg-icon>
      </div>
    </button>

    <div class="row w-100 justify-content-end me-4">
      <div *ngIf="showNotificationToggle"
          class="width-box"
          (scrollingFinished)="loadMoreNotification()"
          appScrollTracker
          @showHideNotification>
        <div class="p-0">
          <div class="d-flex justify-content-between align-items-center border-bottom-custom p-3">
            <h6 class="m-0 headerText-custom fw-bold"
                [style.color]="'#12141F'">
              {{'NAV-BAR.NOTIFICATION'|translate}}
            </h6>
            <button type="button"
                class="btn-close"
                aria-label="Close"
                (click)="closeNotification()">
            </button>
          </div>
          <div class="d-flex flex-wrap gap-2 text-start my-2 ps-3 p-lg-3">
            <button class="btn round-input box-btn-notification "
                type="button"
                [ngClass]="unread  ? 'btn-std-2ry' : 'btn-std-prim'"
                (click)="filterUnreadNotification(null)">
              {{'NAV-BAR.All'|translate}}
            </button>
            <button class="btn round-input box-btn-notification"
                type="button"
                [ngClass]="unread  ? 'btn-std-prim' : 'btn-std-2ry'"
                (click)="filterUnreadNotification(true)">
              {{'NAV-BAR.Unread'|translate}}
            </button>
          </div>


          <div class="text-start pt-2">
            <label class="ps-3 fw-bold text-black">
              {{'NAV-BAR.This week'|translate}}
            </label>
            <div *ngIf="notificationThisWeek?.results.length === 0">
              <div class="py-3 text-center">
                <svg class="icon-empty-page"
                    height="85"
                    viewBox="0 0 380 150"
                    width="450">
                  <use href="assets/images/pictograms/paint.svg#paint"
                      height="80"
                      width="375"
                      y="8"
                      fill="#EAEAED">
                  </use>
                  <use href="assets/images/pictograms/notification.svg#notification"
                      style="fill: #9da0ab"
                      height="140"
                      width="120"
                      x="127">
                  </use>
                </svg>
                <h5 class="fw-bold mb-1"
                    [style.color]="'#12141F'">
                  {{'NAV-BAR.You’re All Caught Up'|translate}}
                </h5>
                <p class="text-gray-neutrals mb-0">
                  {{'NAV-BAR.No notification at this time'|translate}}
                </p>
              </div>
            </div>
            <div class="row mx-0 px-1 py-3 height-box pointer col-12 position-relative align-items-center"
                *ngFor="let notification of notificationThisWeek?.results"
                [ngClass]="{'bg-unread': !notification.read}"
                (click)="clickNotification(notification)">
              <div class="col-auto p-0 d-flex align-items-start mx-3 mt-2"
                  [ngClass]="{'opacity-50': notification.read}">
                <app-profile-display *ngIf="notification?.notification?.actor"
                    [person]="notification?.notification?.actor"
                    [size]="48"></app-profile-display>
                <img *ngIf="!notification?.notification?.actor"
                    src="assets/images/flaticons/bell-notification.png"
                    class="photo-actor">
              </div>
              <div class="col p-0 text-start">
                <p class="custom-box-detail"
                    [ngStyle]="{'color': notification.read ? '#9DA0AB' : ''}"
                    [innerHTML]="translateSidebar.currentLang === 'th'? notification.content: notification.content_en">
                </p>
                <span>
                  <a class="text-time"
                      [class.text-std-color-3]="notification.read">
                    {{ notification.notification.time_since[translateSidebar.currentLang] }}
                  </a>
                </span>
              </div>
              <div class="col-auto p-0 d-flex align-items-start mx-3">
                <div [ngClass]="notification.read ? '':'status-circle'">
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="notificationResult?.results.length"
              class="text-start pt-4">
            <label class="ps-3 fw-bold text-black">
              {{'NAV-BAR.All-time'|translate}}
            </label>
            <div class="row mx-0 px-1 py-3 height-box pointer col-12 position-relative align-items-center"
                *ngFor="let notification of notificationResult?.results"
                [ngClass]="{'bg-unread': !notification.read}"
                (click)="clickNotification(notification)">
              <div class="col-auto p-0 d-flex align-items-start mx-3 mt-2"
                  [ngClass]="{'opacity-50': notification.read}">
                <app-profile-display *ngIf="notification?.notification?.actor"
                    [person]="notification?.notification?.actor"
                    [size]="48"></app-profile-display>
                <img *ngIf="!notification?.notification?.actor"
                    src="assets/images/flaticons/bell-notification.png"
                    class="photo-actor">
              </div>
              <div class="col p-0 text-start">
                <p class="custom-box-detail"
                    [ngStyle]="{'color': notification.read ? '#9DA0AB' : ''}"
                    [innerHTML]="translateSidebar.currentLang === 'th'? notification.content: notification.content_en">
                </p>
                <span>
                  <a class="text-time"
                      [class.text-std-color-3]="notification.read">
                    {{ notification.notification.time_since[translateSidebar.currentLang] }}
                  </a>
                </span>
              </div>
              <div class="col-auto p-0 d-flex align-items-start mx-3">
                <div [ngClass]="notification.read ? '':'status-circle'">
                </div>
              </div>
            </div>
          </div>

          <div class="w-100"
              style="margin: 5px">
            <app-loading-spinner [loading]="isLoading"></app-loading-spinner>
          </div>
        </div>

        <div class="border-top-custom text-start footer-width-box">
          <a class="text-std-color-3"
              href="javascript:void(0)"
              (click)="markAllAsRead()">{{"NAV-BAR.MARK-ALL-AS-READ"|translate}}</a>
        </div>
      </div>
    </div>
  </div>
  <div ngbDropdown
      class="d-none d-md-inline-block">
    <button class="btn btn-lang btn btn-translate d-flex justify-content-center ms-2 me-2"
        (mouseover)="hoverText('lang', true)"
        (mouseleave)="hoverText('lang', false)"
        ngbDropdownToggle
        [ngStyle]="{'color': hoverList.lang ? themeList?.hovered_text_color: themeList?.text_color}"
        id="dropdownLang"
        type="button">

      <svg-icon class="mb-1"
          [src]="'assets/svg/icon-menu/' + (language === 'th' ? 'translate-thai' : 'translate-eng') + '.svg'">
      </svg-icon>

      <span class="ps-1 d-none d-md-inline"
          [ngStyle]="{'color': language ? 'hsl(' + activeSidebarColor + ')' : themeList?.text_color}">
        {{ language === 'th' ? 'TH' : 'EN' }}
      </span>
      <i class="ms-2 fal fa-angle-down"></i>

    </button>
    <div ngbDropdownMenu
        aria-labelledby="dropdownLang"
        class="lang-menu text-center"
        style="left:0">
      <button ngbDropdownItem
          class="text-start select-item-dropdown"
          (click)="changeLanguage('en')"
          [class.bg-selected]="language === 'en' ? 'bg-selected' : null">
        EN
      </button>
      <button ngbDropdownItem
          class="text-start select-item-dropdown"
          (click)="changeLanguage('th')"
          [class.bg-selected]="language === 'th' ? 'bg-selected' : null">
        TH
      </button>
    </div>
  </div>
  <div class="col-auto col-auto pe-lg-0 profile-border me-4"
      [style.--c]="themeList?.text_color">
    <div class="text-white d-flex align-items-center"
        ngbDropdown
        display="dynamic">
      <div class="d-none d-md-inline ms-1 me-2">
        <div class="fw-semibold"
            [style.color]="themeList?.text_color">
          {{ profile.shorted_name }}
        </div>
        <div class="pointer text-end fw-normal fs-base-l4"
            (click)="logOut()"
            [style.color]="themeList?.text_color">{{"SIDEBAR.LOGOUT" | translate}}
        </div>
      </div>
      <div class="d-none d-md-inline">
        <app-profile-display [person]="profile"
            [size]="35"
            [pointer]="true"
            (clickProfile)="navigateToProfile()"></app-profile-display>
      </div>
    </div>
  </div>
</nav>