import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Signal,
  ViewChild,
  inject,
  signal,
} from '@angular/core';
import {
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { DisplayService } from '@core/services/display.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { DeviceType, Primitive } from '@shared/models/common.model';
import * as $ from 'jquery';
import { NotificationService } from 'src/app/shared/service/notification.service';
import {
  ThemeList,
  ThemeService,
} from 'src/app/shared/service/theme.service';
import { Logout } from 'src/app/store/auth/auth.actions';
import { AlertService } from '../../services/alert.service';
import { NavbarService } from '../navbar/navbar.service';
import { TitleService } from '@shared/service/title-service';
import { ProfileService } from 'src/app/modules/profile/shared/profile.service';
import { PermissionService } from '../../services/permission.service';
import { featureFlag } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { MemoService } from 'src/app/modules/memos/service/memo.service';
import { MemoPreviewState } from 'src/app/store/memo-preview/memo-preview.state';
import { CloseMemoPreview } from 'src/app/store/memo-preview/memo-preview.actions';
import { SidebarService } from './sidebar.service';
import { ClearMemoPasswordPopup } from 'src/app/store/memo/memo.actions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  @ViewChild('pageContainer') set pageContainer(
    element: ElementRef | undefined,
  ) {
    this.sidebarService.pageContainerElement$.next(
      element?.nativeElement,
    );
  }

  activeSidebarColor?: string;
  badge: Signal<{ [k: string]: Primitive }> = signal({});
  deviceType: DeviceType = 'desktop';
  navbarActive = false;
  flags: any;
  profile: any;
  showWarning = false;
  getWarningEnableOTPFirstTime = true;
  earlier: string;
  translateSidebar: any;
  page = 1;
  isLoading = false;

  themeList: ThemeList = {
    text_color: '#707485',
    id: 0,
    name: '',
    display_email: '',
    display_pdf: '',
    display_login: '',
    bg_login_color: '',
    bg_login_second_color: '',
    box_login_color: '',
    text_login_color: '',
    text_box_login_color: '',
    display_sidebar: '',
    bg_color_primary: '',
    bg_color_secondary: '',
    hovered_text_color: '',
    header_table_color: '',
    text_header_table_color: '',
    active: false,
    button_login_color: '',
    text_button_login_color: '',
  };

  language: string;
  isFullSidebar = true;
  departmentSubMenu = true;
  manageUploadSubMenu = true;
  openSidebar = false;
  backgroundFade: any = null;
  usageInformation = true;
  manageCertificate = true;
  selectedLanguage: string | any;
  isPreview: boolean;
  subscription: Subscription[] = [];
  colorProfile: string;
  @ViewChild('pageSizeElement', { static: false })
  pageSizeElement: ElementRef;
  @ViewChild('sidebarSize', { static: false })
  sidebarSize: ElementRef;
  getWidthById: number;

  private displayService = inject(DisplayService);
  private sidebarService = inject(SidebarService);

  constructor(
    public notificationService: NotificationService,
    private router: Router,
    private translate: TranslateService,
    private themeService: ThemeService,
    private navbar: NavbarService,
    private alert: AlertService,
    private profileService: ProfileService,
    private permission: PermissionService,
    private store: Store,
    private titleService: TitleService,
    private memoService: MemoService,
    private modalService: NgbModal,
  ) {
    this.subscription.push(
      router.events.subscribe((val) => {
        if (val instanceof NavigationStart) {
          this.store.dispatch(new CloseMemoPreview());
          this.store.dispatch(new ClearMemoPasswordPopup());
          this.modalService.dismissAll();
        }
        if (val instanceof NavigationEnd) {
          this.openSidebar = false;
        }
      }),
    );
    this.subscription.push(
      this.themeService.data.subscribe((theme) => {
        this.activeSidebarColor =
          this.themeService.getHslColorValueByName('std-color-3');
        this.hexToRGBA();
      }),
    );
    this.subscription.push(
      this.titleService.getSidebar().subscribe((res) => {
        this.openSidebar = !this.openSidebar;
        this.isFullSidebar = res;
      }),
    );
    this.subscription.push(
      this.store.subscribe((state) => {
        this.profile = state.auth;
        if (
          featureFlag.ddoc &&
          this.getWarningEnableOTPFirstTime &&
          this.profile &&
          this.profile.id &&
          this.permission.checkAccess(['General'])
        ) {
          this.getWarningEnableOTPFirstTime = false;
          const subscription = this.profileService
            .getWarningEnableOTP(this.profile.person_id)
            .subscribe((data: any) => {
              this.showWarning = data.show_warning;
            });
          this.subscription.push(subscription);
        }
      }),
    );
    this.store.select(MemoPreviewState).subscribe({
      next: (res) => {
        this.isPreview = res.isPreview;
      },
    });
  }

  ngOnInit(): void {
    $('#menu-toggle').click((e: any) => {
      e.preventDefault();
      $('#wrapper').toggleClass('toggled');
    });
    this.flags = [
      { label: 'English', value: 'en' },
      { label: 'Thai', value: 'th' },
    ];
    this.selectedLanguage = localStorage.getItem('lang');
    this.translateSidebar = this.translate;
    this.navbar.data.subscribe((res) => {
      setTimeout(() => {
        this.navbarActive = res;
      });
    });
    this.notificationService.updateNotificationCount();
    this.checkRouteForSubmenu();
    this.observeDisplay();
    this.sidebarService.fetchBadge();
    this.badge = this.sidebarService.getBadge();
  }

  ngOnDestroy(): void {
    this.subscription?.forEach((item) => {
      try {
        item.unsubscribe();
      } catch (e) {
        console.error(e);
      }
    });
  }

  hexToRGBA(): void {
    let hex: any = this.themeList?.hovered_text_color;
    const opacity = 0.1;
    const rgb: any =
      'rgba(' +
      (hex = hex.replace('#', ''))
        .match(new RegExp('(.{' + hex.length / 3 + '})', 'g'))
        .map((l: any) => {
          return parseInt(hex.length % 2 ? l + l : l, 16);
        })
        .concat(isFinite(opacity) ? opacity : 1)
        .join(',') +
      ')';
    this.backgroundFade = rgb;
  }

  checkRouteForSubmenu(): void {
    const manageOrg = [
      { url: 'department' },
      { url: 'users' },
      { url: 'loa' },
      { url: 'cc-group' },
      { url: 'memos/trash' },
    ];

    const usage = [
      { url: 'kpi-dashboard' },
      { url: 'usage-dashboard' },
      { url: 'memo-report' },
      { url: 'operation-log' },
    ];

    const certificate = [
      { url: 'manage-csr' },
      { url: 'certificate' },
    ];
    const url = this.router.url.split('/')[1];
    let checkPathOrg = null;
    let checkPathUsageInformation = null;
    let checkPathCertificate = null;
    checkPathOrg = manageOrg.some((res) => res.url === url);
    checkPathUsageInformation = usage.some((res) => res.url === url);
    checkPathCertificate = certificate.some((res) => res.url === url);
    if (checkPathOrg) {
      this.departmentSubMenu = true;
    }
    if (url === 'manage-types-upload-memo') {
      this.manageUploadSubMenu = true;
    }
    if (checkPathUsageInformation) {
      this.usageInformation = true;
    }

    if (checkPathCertificate) {
      this.manageCertificate = true;
    }
  }

  checkRouteActive(url: string): boolean {
    return url.split('/')[1] === this.router.url.split('/')[1];
  }

  getSizeSidebar() {
    const sidebarWrapperElement = document.getElementById(
      'sidebar-wrapper',
    ) as HTMLElement;
    String(sidebarWrapperElement?.clientWidth);
    const sidebarElement = document.getElementById('sidebar-wrapper');
    if (sidebarElement) {
      const width = sidebarElement.clientWidth; // Get the client width of the element
      this.getWidthById = width; // Convert width to a string with 'px' unit
    }
    this.memoService.setSizePage(
      this.pageSizeElement?.nativeElement.clientWidth,
      this.pageSizeElement?.nativeElement.clientHeight,
      this.getWidthById,
    );
  }

  navigateTo(): void {
    this.router.navigate(['/department', 'level']);
  }

  onResize($event: any) {
    this.openSidebar = false;
    this.memoService.setSizePage(
      this.pageSizeElement.nativeElement.clientWidth,
      this.pageSizeElement.nativeElement.clientHeight,
      this.sidebarSize.nativeElement.clientWidth,
    );
  }

  isUserActive() {
    const currentRoute = this.router.url
      .split('/')[1]
      .substring(0, 5);
    if (currentRoute === 'users') {
      return 'content-user-height';
    } else {
      return 'content-height';
    }
  }

  logOut(): void {
    this.alert
      .confirm(this.translate.instant('SIDEBAR.CONTENT'))
      .then((res) => {
        if (res.value) {
          this.store.dispatch(Logout);
        }
      });
  }

  navigateToProfile(): void {
    this.router.navigate(['/', 'profile-detail', 'detail']);
  }

  get profileName(): string | undefined | null {
    const firstName = this.profile?.first_name;
    const lastName = this.profile?.last_name;

    return firstName
      ? `${firstName[0]?.toUpperCase() + firstName.slice(1)} 
        ${lastName?.[0]?.toUpperCase()}`
      : '';
  }

  changeLanguage(lang: string): void {
    this.selectedLanguage = lang;
    if (lang === 'en') {
      this.translate.use('en');
      localStorage.setItem('lang', 'en');
    } else if (lang === 'th') {
      this.translate.use('th');
      localStorage.setItem('lang', 'th');
    }
  }

  containerScrollingFinished(): void {
    this.sidebarService.setContainerScrollingFinished(true);
  }

  observeDisplay(): void {
    this.displayService.getBreakpointDeviceObserver().subscribe({
      next: (res) => {
        this.deviceType = res;
        // Default opening sidebar
        this.openSidebar = false;
        if (this.deviceType === 'tablet') {
          this.isFullSidebar = false;
        } else {
          this.isFullSidebar = true;
        }
      },
    });
  }

  toggleSidebar(): void {
    if (this.themeService.isMobile()) {
      this.isFullSidebar = true;
      this.openSidebar = !this.openSidebar;
      return;
    }
    this.isFullSidebar = !this.isFullSidebar;
    setTimeout(() => {
      this.getSizeSidebar();
    }, 200);
  }

  closeSidebar(event: Event) {
    if (event) {
      const target = event.target as HTMLElement;
      const classList = target.classList;
      if (
        classList.value === '' ||
        target.tagName === 'BODY' ||
        target.id === 'sidebar-user'
      ) {
        return;
      }
      this.openSidebar = false;
    }
  }
}
