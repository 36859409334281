<div *ngIf="item"
    class="w-100 box d-flex align-items-center py-l12"
    [style.background-color]="item.color.background">
  <div class="icon mx-2"
      [style.box-shadow]="iconBoxShadow"
      [style.background-color]="item.color.foreground">
    <svg height="20"
        width="20"
        viewBox="0 0 20 20">
      <use height="20"
          width="20"
          stroke="white"
          [attr.href]="validIconPath">
      </use>
    </svg>
  </div>
  <div>
    <h6 class="mb-0 fw-medium"
        [style.color]="item.titleColor">
      {{ item.title }}
    </h6>
    <p class="fs-base-l4 text-gray-neutrals">
      {{ item.subTitle }}
    </p>
  </div>
</div>