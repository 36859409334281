import { Injectable } from '@angular/core';
import { DrfList } from '@shared/models/drf-response.models';
import { PeopleListItem } from '@shared/models/user.model';
import { Observable } from 'rxjs/internal/Observable';
import { ApiService } from 'src/app/core/http/api.service';
import { UserDetail } from 'src/app/store/auth/auth.model';
import { Team, Users, UsersProfile } from './users.model';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private http: ApiService) {}

  createUser(user: UsersProfile) {
    return this.http.post(`/api/profiles/`, user);
  }

  updateUser(user: any, id: number) {
    return this.http.patch(`/api/people/${id}/`, user);
  }

  updateUserBlob(user: any, id: number) {
    return this.http.patch(`/api/upload-person-blob/${id}/`, user);
  }

  inviteUser(user: { email: string; role: string; teams: string }) {
    return this.http.post(`/api/invite-user/`, user);
  }

  getM28Signature(
    id: number,
  ): Observable<{ m28_signature_preview: string }> {
    return this.http.get(`/api/people/${id}/m28-signature-preview/`);
  }

  getUser(search?: any) {
    if (search) {
      search.role =
        search.role || search.role === 0 ? search.role : '';
      search.teams = search.teams ? search.teams : '';
      search.search = search.search ? search.search : '';
    }
    return this.http.get<Users>('/api/profiles/', search);
  }

  getUserDetail(id: number) {
    return this.http.get<UsersProfile>(`/api/people/${id}/`);
  }

  getTeam(params?: any) {
    return this.http.get<Team>(`/api/teams/`, params);
  }

  deleteUser(id: number) {
    return this.http.delete<Users>(`/api/profiles/${id}/`);
  }
  deleteInviteUser(id: number) {
    return this.http.delete<Users>(`/api/invite-user/${id}/`);
  }

  disableUser(id: number) {
    return this.http.post(`/api/profiles/${id}/disable_user/`, null);
  }

  activeUser(id: number) {
    return this.http.post(`/api/profiles/${id}/active_user/`, null);
  }

  uploadExcelFile(file: any) {
    return this.http.post(`/api/invite-user/import_excel/`, file);
  }

  getInviteUser(file: any) {
    return this.http.get<Users>(`/api/invite-user/`, file);
  }

  getExampleExcel() {
    return this.http.getBlob<BlobPart>(
      '/api/example-invite-user-file/',
    );
  }

  getPeopleList(params?: any): Observable<DrfList<PeopleListItem>> {
    return this.http.get(`/api/people/`, params);
  }

  createPerson(data: any) {
    return this.http.post(`/api/people/`, data);
  }

  getPersonDetail(id: any) {
    return this.http.get<UserDetail>(`/api/people/` + id + '/');
  }

  updatePerson(id: any, data: any) {
    return this.http.patch(`/api/people/` + id + '/', data);
  }

  getDepartment(params?: any) {
    return this.http.get(`/api/departments/`, params);
  }

  updateUserActive(data: any) {
    return this.http.post(`/api/profile-user-activate/`, data);
  }

  getInviteUserExcelFile() {
    return this.http.getBlob(
      `/api/invite-user/` + 'example-invite-user-file/',
    );
  }

  uploadInviteUserExcelFile(data: any) {
    return this.http.post(
      `/api/invite-user/` + 'import-excel/',
      data,
    );
  }

  // Add User
  getAddUserExcelFile() {
    return this.http.getBlob(
      `/api/add-user/` + 'example-add-user-file/',
    );
  }

  uploadAddUserExcelFile(data: any) {
    return this.http.post(`/api/add-user/` + 'import-excel/', data);
  }

  addUser(data: any) {
    return this.http.post(`/api/add-user/`, data);
  }

  getDepartmentList(data?: any) {
    return this.http.get(`/api/departments/`, data);
  }

  //download excel
  getExcelUser(params?: any) {
    return this.http.get(`/api/people/excel/`, params);
  }

  getPasswordPolicy() {
    return this.http.get(`/api/pw-policy/`);
  }
}
