<div class="d-flex align-items-center justify-content-start my-2 old-design text-dark-grey"
    *ngIf="!newDesign">
  <div class="me-2 mt-1 d-none-sm">{{"MEMOS.SHOW" | translate}}</div>
  <div class="me-2 d-none-sm">
    <ng-select [items]="paginationDropdown"
        class="page-selector custom-pagination mt-1"
        [notFoundText]="'SELECT.No items found'|translate"
        placeholder="{{pageSize}}"
        [clearable]="false"
        (change)="changePageSizeEmit($event)"></ng-select>
  </div>
  <div class="mt-1 d-none-sm">{{"MEMOS.ITEM-PER-PAGE" | translate}}</div>
  <ngb-pagination *ngIf="count"
      [collectionSize]="count"
      [(page)]="page"
      aria-label="count"
      [pageSize]="pageSize"
      [maxSize]="5"
      (pageChange)="changePageEmit()">
    <ng-template ngbPaginationPrevious>{{"PAGINATION.PREV" | translate}}</ng-template>
    <ng-template ngbPaginationNext>{{"PAGINATION.NEXT" | translate}}</ng-template>
  </ngb-pagination>
</div>

<div class="d-flex align-items-center my-2 new-design fw-semibold fs-base-l1"
    *ngIf="newDesign"
    [ngClass]="getJustifyClass()">
  <div class="d-none d-md-flex align-items-center"
      *ngIf="!noneSize">
    <div class="me-3">{{"PAGINATION.SHOWING" | translate}}</div>
    <div class="me-3">
      <ng-select class="std-skin"
          [items]="paginationDropdown"
          placeholder="{{pageSize}}"
          [clearable]="false"
          (change)="changePageSizeEmit($event)"></ng-select>
    </div>
    <div class="">{{"MEMOS.ITEM-PAGE" | translate}} {{ count }}</div>
  </div>
  <div class="me-2"
      *ngIf="noneSize">{{"UPLOAD.PAGE" | translate}}</div>
  <div class="d-flex align-items-center fw-semibold">
    <ngb-pagination *ngIf="count"
        [ngClass]="'std-skin'"
        [boundaryLinks]="boundaryLinks"
        [collectionSize]="count"
        [ellipses]="ellipses"
        [rotate]="rotate"
        [(page)]="page"
        aria-label="count"
        [pageSize]="pageSize"
        [maxSize]="isMobile ? 1 : maxSize"
        (pageChange)="changePageEmit()">
      <ng-template ngbPaginationFirst>
        <svg-icon class="d-flex"
            [svgClass]="'icon-pagination'"
            src="assets/images/icons/arrows/chevron-double-left.svg"
            viewBox="1 0 24 24"
            [svgStyle]="{
              'width.px': 16,
              'height.px': 16,
              'stroke-width': '2px',
            }">
        </svg-icon>
      </ng-template>
      <ng-template ngbPaginationLast>
        <svg-icon class="d-flex"
            [svgClass]="'icon-pagination'"
            src="assets/images/icons/arrows/chevron-double-right.svg"
            viewBox="-1 0 24 24"
            [svgStyle]="{
              'width.px': 16,
              'height.px': 16,
              'stroke-width': '2px',
            }">
        </svg-icon>
      </ng-template>
      <ng-template ngbPaginationPrevious>
        <svg-icon class="d-flex"
            [svgClass]="'icon-pagination'"
            src="assets/images/icons/arrows/chevron-left.svg"
            viewBox="0 0 24 24"
            [svgStyle]="{ 
              'width.px': 16, 
              'height.px': 16, 
              'stroke-width': '2px' ,
            }">
        </svg-icon>
      </ng-template>
      <ng-template ngbPaginationNext>
        <svg-icon class="d-flex"
            [svgClass]="'icon-pagination'"
            src="assets/images/icons/arrows/chevron-right.svg"
            viewBox="-2 0 24 24"
            [svgStyle]="{ 
              'width.px': 16,
              'height.px': 16, 
              'stroke-width': '2px' ,
            }">
        </svg-icon>
      </ng-template>
    </ngb-pagination>
    <div *ngIf="count"
        class="p-0">
      <div class="d-flex align-items-center"
          *ngIf="isShowInputNumber()">
        <app-input-type-number [min]="1"
            [max]="countPage"
            [(model)]="page"
            (modelChange)="debouncer$.next($any($event))">
        </app-input-type-number>
        <span [style.white-space]="'pre'"
            class="fw-semibold">
          {{'UPLOAD.OF' | translate}} {{countPage}} {{'MEMOS.pages'| translate}}
        </span>
      </div>
    </div>
  </div>
</div>