<div class="login-frame bg overflow-y-auto pt-4"
    *ngIf="currentTime"
    [style.background]="'#FA4786'">

  <div class="m-auto welcome-text">
    <div>
      <h1 class="date m-0">{{ currentTime | date: 'HH:mm' }}</h1>
    </div>
    <div>
      <h3 class="slogan m-0">{{ currentDate }}</h3>
    </div>
    <div class="fa-logo m-10">
      <img [src]="'assets/images/logo-ngern-turbo.png'"
          [style.max-height.px]="100">
    </div>
  </div>
  <div class="login-box">
    <div class="login-form config-width-height border"
        [ngStyle]="{'background':themeList?.box_login_color}">

      <!--   ADFS Login   -->
      <ng-container *ngIf="adfsLoginMode">
        <div class="text-center"
            *ngIf="loginMode">
          <h3 class="custom-text-blue custom-text"
              [ngStyle]="{'color':themeList?.text_box_login_color}">
            {{'LOGIN.ADFS-LOGIN'|translate}}</h3>
        </div>
        <div class="row m-b-10 custom-border"
            [ngStyle]="{'border-color':themeList?.text_box_login_color}">
          <div class="col d-flex">
            <i class="me-2 fa fa-user custom-text-blue custom-icon border-none"
                aria-hidden="true"
                [ngStyle]="{'color':themeList?.text_box_login_color}"></i>
            <input type="text"
                autocomplete="off"
                class="border-almost-white  border-none  custom-input"
                name="email"
                [(ngModel)]="email"
                [ngStyle]="{'color':themeList?.text_box_login_color}"
                (keyup.enter)="adfsLogin()"
                placeholder="{{'LOGIN.EMAIL' | translate}}">
          </div>
        </div>
        <button (click)="adfsLogin()"
            [ngStyle]="{
              'background': themeList?.button_login_color,
              'color': themeList?.text_button_login_color
            }"
            class="btn login-bt w-100 mt-4 mb-4 custom-text-white">
          {{'LOGIN.LOGIN' | translate}}
        </button>
        <div class="text-center"
            *ngIf="loginAdfsEnable">
          <p class="m-0 pointer"
              (click)="adfsLoginMode = false;">
            {{ 'LOGIN.SIGN-IN-WITH-LOCAL-ACCOUNT' | translate }}
          </p>
        </div>
      </ng-container>

      <!--  Normal Login    -->
      <ng-container *ngIf="!adfsLoginMode">
        <!--    Login    -->
        <div class="text-center"
            *ngIf="loginMode">
          <h3 class="custom-text-blue custom-text"
              [ngStyle]="{'color':themeList?.text_box_login_color}">
            {{'LOGIN.LOGIN'|translate}}</h3>
        </div>

        <form [formGroup]="loginForm"
            *ngIf="loginMode"
            (ngSubmit)="onSubmit()">
          <div class="row m-b-10 custom-border"
              [ngStyle]="{'border-color':themeList?.text_box_login_color}">
            <div class="col d-flex">
              <i class="me-2 fa fa-user custom-text-blue custom-icon border-none"
                  aria-hidden="true"
                  [ngStyle]="{'color':themeList?.text_box_login_color}"></i>
              <input type="text"
                  autocomplete="off"
                  class="border-almost-white  border-none outline-input custom-input"
                  name="usernameFortesting"
                  placeholder="{{'LOGIN.USERNAME' | translate}}"
                  formControlName="username"
                  [ngStyle]="{'color':themeList?.text_box_login_color}">
            </div>
          </div>
          <div class="row m-b-10 custom-border"
              [ngStyle]="{'border-color':themeList?.text_box_login_color}">
            <div class="col d-flex">
              <i class="me-2 fa fa-lock custom-text-blue custom-icon border-none"
                  aria-hidden="true"
                  [ngStyle]="{'color':themeList?.text_box_login_color}"></i>
              <input type="password"
                  autocomplete="off"
                  class="ps-1 border-almost-white  border-none outline-input custom-input"
                  name="passwordFortesting"
                  placeholder="{{'LOGIN.PASSWORD' | translate}}"
                  formControlName="password"
                  [ngStyle]="{'color':themeList?.text_box_login_color}">
            </div>
          </div>
          <button type="submit"
              [ngStyle]="{
                'background': themeList?.button_login_color,
                'color': themeList?.text_button_login_color
              }"
              class="btn login-bt w-100 mt-4 mb-4 custom-text-white"
              href="javascript:void(0)">
            {{'LOGIN.LOGIN' | translate}}
          </button>
        </form>

        <button *ngIf="loginThaiDEnable && loginMode"
            (click)="loginThaiD()"
            [ngStyle]="{
              'background': themeList?.button_login_color,
              'color': themeList?.text_button_login_color
            }"
            class="btn login-bt w-100 mb-4 custom-text-white">
          {{ 'LOGIN.SIGN-IN-WITH-THAI-D' | translate }}
        </button>

        <div class="forget-password-description"
            *ngIf="loginMode">
          <p (click)="toggleForgetPassword()"
              class="custom-text-blue pointer"
              [ngStyle]="{'color':themeList?.text_box_login_color}">
            {{'LOGIN.FORGOT-PASSWORD' | translate}}
          </p>
        </div>

        <div class="text-center"
            *ngIf="loginAdfsEnable && loginMode">
          <p class="m-0 pointer"
              (click)="adfsLoginMode = true;">
            {{ 'LOGIN.SIGN-IN-WITH-ADFS-ACCOUNT' | translate }}
          </p>
        </div>

        <!--   Forget Password     -->
        <ng-container *ngIf="forgotPasswordMode">
          <div class="text-center"
              *ngIf="!forgotPasswordSuccess">
            <h3 class="custom-text-blue custom-text"
                [ngStyle]="{'color':themeList?.text_box_login_color}">
              {{'LOGIN.FORGOT-PASSWORD'| translate}}</h3>
          </div>
          <div class="forget-password-description">
            <p class="custom-text-blue mt-2"
                [ngStyle]="{'color':themeList?.text_box_login_color}">
              {{textList | translate }}
            </p>
          </div>
          <div *ngIf="!forgotPasswordSuccess"
              class="row m-b-10">
            <div class="col custom-border mb-2"
                [ngStyle]="{'border-color':themeList?.text_box_login_color}">
              <input type="text"
                  autocomplete="off"
                  class="border-almost-white  border-none outline-input custom-input"
                  name="email"
                  placeholder="{{'LOGIN.EMAIL' | translate}}"
                  [ngStyle]="{'color':themeList?.text_box_login_color}"
                  [(ngModel)]="email">
            </div>
          </div>
          <div *ngIf="!forgotPasswordSuccess"
              class="custom-ms-recaptcha">
            <form class="custom-ml mt-1"
                [formGroup]="aFormGroup">
              <ngx-recaptcha2 #captchaElem
                  [siteKey]="siteKey"
                  (reset)="handleReset()"
                  (expire)="handleExpire()"
                  (error)="handleError()"
                  (load)="handleLoad()"
                  (success)="handleSuccess($event)"
                  [useGlobalDomain]="false"
                  [size]="size"
                  [hl]="lang"
                  [theme]="theme"
                  [type]="type"
                  class="custom"
                  formControlName="recaptcha">
              </ngx-recaptcha2>
            </form>
          </div>

          <button *ngIf="!forgotPasswordSuccess"
              class="mt-2 btn w-100"
              type="button"
              [ngStyle]="{
                'background': themeList?.button_login_color,
                'color': themeList?.text_button_login_color
              }"
              href="javascript:void(0)"
              [disabled]="!captchaSuccess"
              (click)="submitForgotPassword()">
            {{'LOGIN.SEND' | translate}}
          </button>
          <button class="btn btn-outline w-100 mt-2"
              (click)="gotoLogin()"
              [style.--c]="themeList?.button_login_color"
              [style.--bg]="'#fff'">
            {{'LOGIN.BACK-LOGIN' | translate}}
          </button>
        </ng-container>
      </ng-container>
    </div>

    <div class=" translate mt-2 mx-auto">
      <ul>
        <li class="thai-lg"
            [ngClass]="{'select-language': this.language === 'en'}"
            (click)="changeLanguage('th')">ไทย
        </li>
        <li class="eng-lg"
            [ngClass]="{'select-language': this.language === 'th'}"
            (click)="changeLanguage('en')">EN
        </li>
      </ul>
    </div>
  </div>
  <div class="version">
    <p class="m-0">v.{{version}}</p>
  </div>
</div>

<ng-template #wrongPasswordAttempt
    let-c="close"
    let-d="dismiss">
  <div class="modal-header border-bottom-0">
    <button type="button"
        class="btn-close"
        aria-label="Close"
        (click)="closePopUp(); d('Cross click')">
    </button>
  </div>
  <div class="modal-body text-center wrong-password">
    <svg-icon src="assets/svg/lock-red.svg">
    </svg-icon>
    <ng-container *ngIf="remainingAttempts > 0">
      <p class="fw-bold mt-4 title">{{"AUTH.Your account will be locked" | translate}}</p>
      <p class="mt-4">{{"AUTH.Login with" | translate}} <span class="fw-bold">{{f.username.value}}</span></p>
      <p>{{"AUTH.will be locked due to multiple failed login attempts" | translate}}</p>
      <p class="text-asterisk fw-bold">{{"AUTH.You’ll have" | translate}}
        {{remainingAttempts}}
        {{"AUTH.more attempt before your account is locked for 10 minutes"|translate}}
      </p>
      <p>{{"AUTH.or click forget your password to reset it."|translate}}</p>
      <button type="button"
          [ngStyle]="{
                'background': themeList?.button_login_color,
                'color': themeList?.text_button_login_color
              }"
          class="btn my-4 py-2"
          style="width: 85%"
          href="javascript:void(0)"
          (click)="closePopUp(); toggleForgetPassword()">
        {{'LOGIN.FORGOT-PASSWORD' | translate}}
      </button>
    </ng-container>
    <ng-container *ngIf="remainingAttempts <= 0 && remainingAttempts !== -999">
      <p class="fw-bold mt-4 title">{{"AUTH.Your account has been locked"|translate}}</p>
      <p class="mt-4">{{"AUTH.Login with" | translate}} <span class="fw-bold">{{f.username.value}}</span></p>
      <p>{{"AUTH.has been temporarily locked"|translate}}</p>
      <p>{{"AUTH.due to multiple failed login attempts, Please try again later"|translate}}</p>
      <p *ngIf="currentLang === 'th'">{{"AUTH.Please try again later"|translate}}</p>
      <p>{{"AUTH.or click forget your password to reset it."|translate}}</p>
      <button type="button"
          [ngStyle]="{
                'background': themeList?.button_login_color,
                'color': themeList?.text_button_login_color
              }"
          class="btn my-4 py-2"
          style="width: 85%"
          href="javascript:void(0)"
          (click)="closePopUp(); toggleForgetPassword()">
        {{'LOGIN.FORGOT-PASSWORD' | translate}}
      </button>
    </ng-container>
    <ng-container *ngIf="remainingAttempts === -999">
      <p class="fw-bold mt-4 title mb-0">
        {{"AUTH.You are unable to login because your account"|translate}}
      </p>
      <p class="fw-bold title">
        {{"AUTH.has been temporarily disabled."|translate}}
      </p>
      <p>{{"AUTH.If you want to use this account, please cantact admin."|translate}}</p>
      <button type="button"
          [ngStyle]="{
                'background': themeList?.button_login_color,
                'color': themeList?.text_button_login_color
              }"
          class="btn my-4 py-2"
          style="width: 85%"
          href="javascript:void(0)"
          (click)="closePopUp(); d('Cross click');">
        {{"UPLOAD.OK"|translate}}
      </button>
    </ng-container>
  </div>
</ng-template>