import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  ThemeList,
  ThemeService,
} from 'src/app/shared/service/theme.service';
import { ProfileService } from '../../shared/profile.service';
import { GroundColor } from '@shared/models/common.model';
import { Tab } from '@shared/components/slide-tab-bar/slide-tab-bar.component';

@Component({
  selector: 'app-text-signature',
  templateUrl: './text-signature.component.html',
  styleUrls: ['./text-signature.component.scss'],
})
export class TextSignatureComponent
  implements AfterViewChecked, OnDestroy
{
  @Input() abbreviatedName: string;
  @Input() finalTargetHeight = 300;
  @Input() fullName: string;
  @Input() onlyFullName = false;
  @Input() selectOnly = false;
  @Input() isModal = false;
  @Input() selectedSignature: TextSignatureType = 'full';
  @Output() abbreviatedNameChange = new EventEmitter<string>();
  @Output() fullNameChange = new EventEmitter<string>();
  @Output() cancelClick = new EventEmitter();
  @Output() signatureSaveClick = new EventEmitter<Blob>();
  themeList?: ThemeList;
  subscription: Subscription[] = [];
  tabColor: Partial<GroundColor> = {
    foreground: 'hsl(var(--std-color-3))',
    background: 'hsl(var(--std-color-2))',
  };
  tabTextColor = 'hsl(var(--std-color-3))';

  tabDefaultColor = { text: '#707485', icon: '#707485' };
  tabs: Tab[] = [
    {
      label: 'PROFILE.Full Name',
      value: 'full',
      badge: 0,
      color: this.tabColor,
      defaultColor: this.tabDefaultColor,
      iconPath: '',
    },
    {
      label: 'PROFILE.Initials',
      value: 'abbreviate',
      badge: 0,
      color: this.tabColor,
      defaultColor: this.tabDefaultColor,
      iconPath: '',
    },
  ];

  fontOptions = [
    { id: 1, name: 'BaiJamjuree' },
    { id: 2, name: 'NotoSansThai' },
    { id: 3, name: 'Mitr' },
    { id: 4, name: 'Taviraj' },
    { id: 5, name: 'Sriracha' },
  ];
  selectFont: number;

  @Output() selectedTabChange = new EventEmitter<
    string | undefined | null
  >();

  constructor(
    private profileService: ProfileService,
    private themeService: ThemeService,
  ) {
    this.subscription.push(
      this.themeService.data.subscribe((theme) => {
        this.themeList = theme;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription?.forEach((item) => {
      try {
        item.unsubscribe();
      } catch (e) {
        console.error(e);
      }
    });
  }

  ngAfterViewChecked(): void {
    const className =
      this.selectedSignature === 'full'
        ? 'fullname'
        : 'abbreviated-name';
    const canvasCount = 5; //canvas count
    for (let i = 1; i <= canvasCount; i++) {
      const canvasId = `canvas-${className}-${i}`;
      const canvasElement = document.getElementById(
        canvasId,
      ) as HTMLCanvasElement | null;

      if (!canvasElement) {
        return;
      }

      if (this.isCanvasFullWidth(canvasElement)) {
        if (this.selectedSignature === 'full') {
          this.initializeFullNameCanvas(canvasElement);
        } else {
          this.initializeAbbreviatedNameCanvas(canvasElement);
        }
      }
    }
  }

  /** Adjusted the width of canvas to equal its element */
  adjustCanvasToFullWidth(elRef: HTMLCanvasElement): void {
    elRef.width = elRef?.clientWidth;
  }

  initializeAbbreviatedNameCanvas(elRef: HTMLCanvasElement): void {
    this.adjustCanvasToFullWidth(elRef);
    if (!this._abbreviatedName) {
      return;
    }

    const fonts = [
      'Prompt',
      'NotoSansThai',
      'Mitr',
      'Taviraj',
      'Sriracha',
    ];
    for (const font of fonts) {
      this.redrawTextSignature(this._abbreviatedName, elRef, true);
    }
  }

  initializeFullNameCanvas(elRef: HTMLCanvasElement): void {
    this.adjustCanvasToFullWidth(elRef);
    if (!this._fullName) {
      return;
    }
    this.redrawTextSignature(this._fullName, elRef);
  }

  onFullNameChange() {
    const isFullName = this.selectedSignature === 'full';
    const canvasCount = 5; //canvas count
    for (let i = 1; i <= canvasCount; i++) {
      const canvasId = isFullName
        ? `canvas-fullname-${i}`
        : `canvas-abbreviated-name-${i}`;
      const canvasElement = document.getElementById(
        canvasId,
      ) as HTMLCanvasElement | null;
      if (!canvasElement) {
        return;
      }
      const name = isFullName
        ? this._fullName
        : this._abbreviatedName;
      const initial = !isFullName;

      this.redrawTextSignature(name, canvasElement, initial);
    }
  }

  redrawTextSignature(
    text: string,
    canvasElement: HTMLCanvasElement,
    initial = false,
  ) {
    const ctx = canvasElement?.getContext('2d');
    if (!ctx) {
      console.error('Failed to get canvas context');
      return;
    }

    const clearContext = () => {
      ctx.clearRect(
        0,
        0,
        canvasElement?.width,
        canvasElement?.height,
      );
    };
    const setFontSize = (size = 18) => {
      const fontMap: { [k: string]: string } = {
        'canvas-abbreviated-name-1': 'Prompt',
        'canvas-abbreviated-name-2': 'NotoSansThai',
        'canvas-abbreviated-name-3': 'Mitr',
        'canvas-abbreviated-name-4': 'Taviraj',
        'canvas-abbreviated-name-5': 'Sriracha',
        'canvas-fullname-1': 'Prompt',
        'canvas-fullname-2': 'NotoSansThai',
        'canvas-fullname-3': 'Mitr',
        'canvas-fullname-4': 'Taviraj',
        'canvas-fullname-5': 'Sriracha',
      };

      for (const canvasId in fontMap) {
        if (ctx.canvas.id === canvasId) {
          ctx.font = `${size}px ${fontMap[canvasId]}`;
          break;
        }
      }
    };

    clearContext();
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    let fontSize = canvasElement.width * 0.08;
    setFontSize(fontSize);
    const textWidth = ctx.measureText(text).width;
    if (textWidth > canvasElement.width * 0.8) {
      fontSize = canvasElement.width * 0.8 * (fontSize / textWidth);
      setFontSize(fontSize);
    }
    const drawText = () => {
      clearContext();
      ctx.fillText(
        text,
        canvasElement.width * 0.5,
        canvasElement.height * 0.5,
      );
    };

    // Ensure fonts are loaded before drawing text
    const fontToLoad = ctx.font.match(/(\d+px\s)(.*)/)?.[2];
    if (fontToLoad) {
      document.fonts
        .load(`10px ${fontToLoad}`)
        .then(() => {
          drawText();
        })
        .catch((err) => {
          console.error('Fonts did not load in time:', err);
          drawText();
        });
    } else {
      drawText();
    }
  }

  /** Check that does the width of canvas the equal element width */
  isCanvasFullWidth(elRef: HTMLCanvasElement): boolean {
    const canvasWidth = elRef.width;
    const canvasClientWidth = elRef.clientWidth;
    return canvasWidth !== canvasClientWidth;
  }

  finiteSignature(): Observable<Blob> {
    const fontId = this.fontOptions.filter(
      (items) => items.id === this.selectFont,
    );
    const setFontSize = (size = 18) => {
      ctx.font = `${size}px ${
        fontId[0]?.name ? fontId[0].name : 'Prompt'
      }`;
    };
    /** Create new instance canvas */
    const newCanvas = document.createElement('canvas');
    newCanvas.style.display = 'none';
    newCanvas.height = this.finalTargetHeight;
    /** Adjust the size of canvas */
    const ctx: any = newCanvas.getContext('2d');
    const fontSize = this.finalTargetHeight * 0.5;
    setFontSize(fontSize);
    const textWidth = ctx.measureText(
      this.selectedSignatureName,
    ).width;
    newCanvas.width = textWidth + fontSize * 1.5;
    setFontSize(fontSize);
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(
      this.selectedSignatureName,
      newCanvas.width * 0.5,
      newCanvas.height * 0.55,
    );
    /** The final signature image */
    const subject = new Subject<Blob>();
    newCanvas.toBlob((res: any) => {
      subject.next(res);
    });
    return subject.pipe(take(1));
  }

  onCancelClick(): void {
    this.cancelClick.emit();
  }

  onConfirmClick(): void {
    const subscription = this.finiteSignature().subscribe({
      next: (res) => {
        const signatureFile = new File([res], 'online-sign.png', {
          type: res.type,
        });
        this.signatureSaveClick.emit(signatureFile);
      },
    });
    this.subscription.push(subscription);
  }

  onTextSignatureSelect(type: TextSignatureType, idx: number): void {
    this.selectFont = idx;
    this.selectedSignature = type;
  }

  onSelectedTabChange(tabValue?: string | null): void {
    this.selectedSignature = (tabValue ||
      'full') as TextSignatureType;
    this.selectedTabChange.emit(tabValue);
  }

  get _fullName(): string {
    return this.fullName;
  }

  set _fullName(val: string) {
    this.fullName = val;
    this.fullNameChange.emit(val);
  }

  get selectedSignatureName(): string {
    return this.selectedSignature === 'full'
      ? this.fullName
      : this._abbreviatedName;
  }

  get _abbreviatedName(): string {
    return (
      this.abbreviatedName ||
      this.profileService.abbreviateFullName(this._fullName)
    );
  }

  set _abbreviatedName(val: string) {
    this.abbreviatedName = val;
    this.abbreviatedNameChange.emit(val);
  }
}

export type TextSignatureType = 'full' | 'abbreviate';
