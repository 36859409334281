<ng-container *ngIf="customHeader; else defaultHeader">
  <ng-container [ngTemplateOutlet]="customHeader.templateRef"></ng-container>
</ng-container>
<ng-template #defaultHeader>
  <div class="modal-header">
    <h4 class="mb-0">
      {{title | translate}}
    </h4>
    <button type="button"
        class="btn-close"
        (click)="close()"
        aria-label="Close">
    </button>
  </div>
</ng-template>

<ng-container *ngIf="customBody; else defaultBody">
  <ng-container [ngTemplateOutlet]="customBody.templateRef"></ng-container>
</ng-container>
<ng-template #defaultBody>
  <div class="modal-body text-center">
    <label class="f-18">
      {{ body | translate }}
    </label>
  </div>
</ng-template>
<ng-container *ngIf="customFooter; else defaultFooter">
  <ng-container [ngTemplateOutlet]="customFooter.templateRef"></ng-container>
</ng-container>
<ng-template #defaultFooter>
  <div class="modal-footer">
    <button class="btn btn-outline"
        (click)="close()"
        [style.--c]="themeList?.header_table_color">
      {{ (!btnCancel ? 'MEMOS.CANCEL' : btnCancel) | translate }}
    </button>
    <button class="btn btn-confirm"
        type="button"
        appSetTheme
        [disabled]="disableBtn"
        [ladda]="laddaLoading"
        (click)="submit()">
      {{ (!btnConfirm ? 'MEMOS.CONFIRM' : btnConfirm) | translate }}
    </button>
  </div>
</ng-template>