import {
  inject,
  Injectable,
  signal,
  Signal,
  WritableSignal,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Primitive } from '@shared/models/common.model';
import { NotificationService } from '@shared/service/notification.service';
import {
  BehaviorSubject,
  catchError,
  first,
  forkJoin,
  map,
  Observable,
  take,
  tap,
} from 'rxjs';
import { MemoService } from 'src/app/modules/memos/service/memo.service';
import { ErrorNotification } from 'src/app/store/memo/memo.actions';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  badgeSource: { [k: string]: Observable<Primitive> };
  containerScrollingFinishedSubject = new BehaviorSubject<boolean>(
    false,
  );
  containerScrollingFinished =
    this.containerScrollingFinishedSubject.asObservable();
  pageContainerElement$ = new BehaviorSubject<
    HTMLDivElement | undefined
  >(undefined);

  private badgeStore: WritableSignal<{ [k: string]: Primitive }> =
    signal({});
  private memoService = inject(MemoService);
  private notificationService = inject(NotificationService);
  private store = inject(Store);
  private updateBadge$ = new BehaviorSubject<
    string[] | undefined | null
  >(undefined);

  constructor() {
    this.badgeSource = {
      allMemo: this.memoService
        .getGeneralMemoCount()
        .pipe(map((res) => res.count)),
      // TODO: add myTask to this
      // * Push other badge source to this
    };
    this.fetchBadge();
  }

  fetchBadge(
    specify?: string[],
  ): Observable<{ [k: string]: Primitive }> {
    const source = specify
      ? specify.reduce((obj, key) => {
          if (this.badgeSource[key]) {
            obj[key] = this.badgeSource[key];
          }
          return obj;
        }, {} as { [k: string]: Observable<Primitive> })
      : this.badgeSource;
    const obs = forkJoin(source).pipe(
      tap((res) => {
        return this.badgeStore.update((badge) => ({
          ...badge,
          ...res,
        }));
      }),
      take(1),
      catchError((err) => {
        this.store.dispatch(new ErrorNotification(err));
        throw err;
      }),
    );
    obs.subscribe();
    return obs;
  }

  getBadge(): Signal<{ [k: string]: Primitive }> {
    return this.badgeStore;
  }

  setContainerScrollingFinished(event: boolean) {
    this.containerScrollingFinishedSubject.next(event);
  }

  getPageContainerElement(): Observable<HTMLDivElement | undefined> {
    return this.pageContainerElement$.pipe(
      first((ev) => ev != null),
      take(1),
    );
  }
}
