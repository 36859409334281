import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ThemeList, ThemeService } from '../../service/theme.service';
import { Subject, Subscription, debounceTime } from 'rxjs';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnDestroy {
  @ViewChild('maxLineInput') maxLineInput: ElementRef;

  @Input() boundaryLinks: boolean;
  @Input() count: number;
  @Input() ellipses: boolean;
  @Input() rotate: boolean;
  @Input() pageSize: number;
  @Input() newDesign = false;
  @Input() maxSize = 5;
  @Output() pageSizeChange = new EventEmitter();

  @Input() page: number;
  @Output() pageChange = new EventEmitter();
  @Input() noneSize: boolean;
  @Input() pageTitle: string;

  isMobile: boolean;
  themeList?: ThemeList;
  paginationDropdown = ['10', '20', '30', '40', '50'];
  themeSubscription: Subscription[] = [];
  debouncer$ = new Subject<string>();

  constructor(private themeService: ThemeService) {
    this.debouncer$
      .pipe(debounceTime(10))
      .subscribe((pageCount: string) => {
        this.page = Number(pageCount);
        this.changePageEmit();
      });
    this.isMobile = this.themeService.isMobile();
    this.themeSubscription.push(
      this.themeService.data.subscribe((theme) => {
        this.themeList = theme;
      }),
    );
  }

  ngOnDestroy(): void {
    this.themeSubscription?.forEach((item) => {
      try {
        item.unsubscribe();
      } catch (e) {
        console.error(e);
      }
    });
  }

  changePageSizeEmit(e: any) {
    this.page = 1;
    this.pageSize = e;
    this.pageSizeChange.emit(this.pageSize);
  }

  changePageEmit() {
    this.pageChange.emit(this.page);
  }

  getJustifyClass() {
    const isIpadSize = window.innerWidth < 1367;
    const isMobileSize = window.innerWidth < 577;

    if (
      isMobileSize ||
      (this.pageTitle === 'userPage' && isIpadSize)
    ) {
      return 'justify-content-end';
    } else {
      return 'justify-content-between';
    }
  }

  isShowInputNumber() {
    return ![
      'userPage',
      'approvalRequestPage',
      'newFeedPage',
    ].includes(this.pageTitle);
  }

  disabledOnWheel(event: WheelEvent) {
    event.preventDefault();
    this.maxLineInput?.nativeElement.blur();
  }

  get countPage() {
    return Math.ceil(this.count / this.pageSize);
  }
}
