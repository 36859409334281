<span class="input">
  <input class="number-input"
      [disabled]="displayIsTablet"
      type="number"
      [min]="min"
      [max]="max"
      [(ngModel)]="model"
      (ngModelChange)="emit()">
  <div *ngIf="!displayIsTablet"
      class="spinners">
    <svg class="pointer"
        height="10"
        stroke="currentColor"
        width="10"
        (click)="increment()">
      <use height="10"
          width="10"
          href="assets/images/icons/arrows/arrow-up.svg#arrow-up">
      </use>
    </svg>
    <svg class="pointer"
        height="10"
        stroke="currentColor"
        width="10"
        (click)="decrement()">
      <use height="10"
          width="10"
          href="assets/images/icons/arrows/arrow-down.svg#arrow-down">
      </use>
    </svg>
  </div>
  <div *ngIf="displayIsTablet"
      class="spinners-left">
    <svg class="pointer"
        height="10"
        stroke="currentColor"
        width="10"
        (click)="decrement()">
      <use height="10"
          width="10"
          href="assets/images/icons/arrows/arrow-down.svg#arrow-down">
      </use>
    </svg>
  </div>
  <div *ngIf="displayIsTablet"
      class="spinners-right">
    <svg class="pointer"
        height="10"
        stroke="currentColor"
        width="10"
        (click)="increment()">
      <use height="10"
          width="10"
          href="assets/images/icons/arrows/arrow-up.svg#arrow-up">
      </use>
    </svg>
  </div>
</span>