import { Component, OnInit, NgZone } from '@angular/core';
import { AuthenticationService } from '../../authentication/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthState } from '../../../../app/store/auth/auth.state';
import { Store } from '@ngxs/store';
import {
  Logout,
  GetUserById,
} from '../../../../app/store/auth/auth.actions';

@Component({
  selector: 'app-adfs-callback',
  templateUrl: './adfs-callback.component.html',
  styleUrls: ['./adfs-callback.component.scss'],
})
export class AdfsCallbackComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private authState: AuthState,
    private zone: NgZone,
  ) {}

  ngOnInit(): void {
    localStorage.clear();
    let data: any = null;

    try {
      const queryParams: any = this.route.snapshot.queryParams;
      let data_base64 = queryParams.data_base64;
      data_base64 = data_base64.replace(/_/g, '/');
      data_base64 = data_base64.replace(/-/g, '+');
      const data_json_str = atob(data_base64);
      data = JSON.parse(data_json_str);
    } catch (e) {
      data = null;
      this.router.navigate(['/login']);
    }

    if (data != null) {
      this.authState.setUserDataToStorage(data);
      data.role = data.role.replace(
        // eslint-disable-next-line no-useless-escape
        /[&\/\\#,+()[\]$~%.'":*?<>{}]/g,
        '',
      );
      this.store.dispatch(GetUserById).subscribe(() => {
        if (!data.is_set_password || !data.is_password_valid) {
          this.zone.run(() => {
            this.router.navigate(['/reset-password']);
            setTimeout(() => {
              location.reload();
            }, 500);
          });
          return;
        }
        if (data.role !== 'General') {
          this.zone.run(() => {
            this.router.navigate(['/users']);
          });
        } else {
          this.zone.run(() => {
            this.router.navigate(['/memos']);
          });
        }
      });
    }
  }
}
