<div class="position-relative">
  <input class="custom-datepicker form-control w-100 h-input"
      ngbDatepicker
      #datepicker="ngbDatepicker"
      [class.submitted]="validateSubmitted"
      [class.input-validate]="validateInput"
      name="datepicker"
      [minDate]="today"
      [(ngModel)]="date"
      [placeholder]="'MEMOS.SELECT'|translate"
      (dateSelect)="calculateDays()"
      autocomplete="off"
      readOnly
      (click)="!noExpired && datepicker.toggle();"
      [ngClass]="{'is-invalid': errorMsg }"
      [class.is-invalid]="errorMsg && !date && !validateInput"
      [disabled]="isDisabled"
      [footerTemplate]="dateFooter"
      [firstDayOfWeek]="7">
  <div class="container-icon"
      (click)="!noExpired && datepicker.toggle()">
  </div>
  <label *ngIf="errorMsg && !validateInput"
      class="text-asterisk fs-base-l2">
    {{errorMsg | translate}}
  </label>
  <span class="span-calendar"
      [ngClass]="errorMsg ? 'invalid-span-calendar': 'is-invalid'"
      [ngStyle]="errorMsg && !date && !validateInput ? { 'right': '25px'} : {}">
    <svg class="icon-size-res text-gray-pantone"
        viewBox="0 0 24 24"
        stroke="currentColor"
        (click)="datepicker.toggle()">
      <use height="24"
          width="24"
          [attr.href]="'assets/images/icons/time/calendar.svg#calendar'">
      </use>
    </svg>
  </span>
</div>
<ng-template #dateFooter>
  <button type="button"
      class="btn btn-std-sm btn-std-2ry shadow-none btn-std-color-1 m-2 float-start"
      (click)="clearDate()">{{'MEMOS.CANCEL' | translate}}
  </button>
  <button type="button"
      class="btn btn-std-sm btn-std-prim m-2 float-end"
      (click)="datepicker?.close()">{{'MEMOS.DONE' | translate}}
  </button>
</ng-template>