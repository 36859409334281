<div class="container-tabs">
  <div #tabWrapper 
      class="tab-wrapper scrollbar-invisible mx-0">
    <div class="tab-row row gx-1 flex-nowrap align-items-center position-relative">
      <div *ngFor="let tab of tabs; index as i"
          class="col-max tab-item"
          [class.active]="tab.value === selectedTab"
          [style.color]="tab.color.foreground"
          [style.background-color]="!enableAnimation && tab.value === selectedTab
            ? tab.color.background
            : 'transparent'"
          (click)="selectTab(tab)">
        <div class="d-flex align-items-center justify-content-center h-100">
          <svg *ngIf="showIcon === 'show' || (showIcon === 'exist' && tab.iconPath)"
              class="icon-status"
              stroke="currentColor"
              viewBox="0 0 20 20"
              [style.color]="tab.value !== selectedTab && tab.defaultColor?.icon
                ? tab.defaultColor?.icon
                : ''">
            <use height="20"
                width="20"
                [attr.href]="tab.iconPath"></use>
          </svg>
          <label *ngIf="!(standalone && tab.value !== selectedTab)"
              class="tab-label fs-l2 fs-base-lg-u0 text-nowrap mb-0 ms-2"
              [class.fw-semibold]="tab.value === selectedTab"
              [style.color]="tab.value !== selectedTab && tab.defaultColor?.text
                ? tab.defaultColor?.text
                : ''">
            {{ tab.label | translate }}
          </label>
          <div *ngIf="showBadge"
              class="badge badge-number rounded-pill text-reset ms-2"
              [style.background-color]="tab.value === selectedTab ? 'white' : tab.color.background">
            <span>{{ tab.badge || 0 }}</span>
          </div>
        </div>
      </div>
      <div *ngIf="enableAnimation && selectedTabItemIndex != null" 
          class="slide-selector"
          [class.resizing]="isResizing"
          [style.background-color]="tabs[selectedTabItemIndex].color.background"
          [style.left.px]="getLeftStartWidth()"
          [style.width.px]="tabItemWidths[selectedTabItemIndex] || 0">
      </div>
    </div>
  </div>
  <div *ngIf="isPreviousButtonVisible"
      class="btn btn-std-outline btn-scroll-left d-flex align-items-center justify-content-center text-black-pantone"
      (click)="onPreviousClick()"
      (mousedown)="onNavigateHold('left')"
      (mouseup)="onNavigateRelease()">
    <svg height="16" 
        stroke="currentColor"
        width="16">
      <use height="16"
          width="16"
          href="assets/images/icons/arrows/chevron-left.svg#chevron-left">
      </use>
    </svg>
  </div>
  <div *ngIf="isNextButtonVisible"
      class="btn btn-std-outline btn-scroll-right d-flex align-items-center justify-content-center text-black-pantone"
      (click)="onNextClick()"
      (mousedown)="onNavigateHold('right')"
      (mouseup)="onNavigateRelease()">
    <svg height="16" 
        stroke="currentColor"
        width="16">
      <use height="16"
          width="16"
          href="assets/images/icons/arrows/chevron-right.svg#chevron-right">
      </use>
    </svg>
  </div>
</div>