<div (click)="onClick()"
    [ngClass]="{'pointer': pointer}">
  <ng-container *ngIf="photo; else initialDisplay">
    <img [src]="(photo | secure | async) "
        class="display-profile"
        [style.width.px]="size"
        [style.height.px]="size"
        [ngStyle]="{'border-color': borderColor || ''}">
  </ng-container>
  <ng-template #initialDisplay>
    <ng-container [ngSwitch]="!!defaultPhoto">
      <img *ngSwitchCase="true"
          [src]="defaultPhoto"
          [style.width.px]="size"
          [style.height.px]="size">
      <div *ngSwitchDefault
          class="display-profile"
          [style.width.px]="size"
          [style.height.px]="size"
          [style.font-size.px]="size * 0.5"
          [style.background]="colorProfile"
          [style.border-color]="borderColor || colorProfile">
        {{ initialName }}
      </div>
    </ng-container>
  </ng-template>
</div>