<ng-select class="std-skin bubble-dropdown"
    [appendTo]="appendTo"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [class.search-in-dropdown]="showSearchInDropdown"
    [closeOnSelect]="!showCheckbox"
    [loading]="loading"
    [items]="items"
    [multiple]="true"
    [(ngModel)]="selected"
    (ngModelChange)="onValueChange($event)">
  <ng-template ng-multi-label-tmp
      let-items="items"
      let-clear="clear">
    <div *ngFor="let item of items"
        class="selected-item col-auto p-base-l12">
      <div class="bubble">
        <ng-container *ngIf="showImage">
          <ng-container *ngIf="selectedChoiceImgTemp; else defaultImageTemp">
            <ng-container [ngTemplateOutlet]="selectedChoiceImgTemp"
                [ngTemplateOutletContext]="{ item: item }">
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-template #defaultImageTemp>
          <img alt="profile"
              [src]="getImage(item) || defaultImage">
        </ng-template>
        <ng-container *ngIf="labelTemp; else defaultLabelTemp">
          <ng-container [ngTemplateOutlet]="labelTemp"
              [ngTemplateOutletContext]="{ item: item }">
          </ng-container>
        </ng-container>
        <ng-template #defaultLabelTemp>
          <span>{{ getLabel(item) }}</span>
        </ng-template>
        <button type="button"
            class="btn-close custom-icon text-gray-pantone ms-base-l2 p-0"
            aria-label="Close"
            (click)="clear(item)">
          <svg viewBox="0 0 24 24">
            <use href="assets/images/icons/marks/x.svg#x"
                height="24"
                width="24">
            </use>
          </svg>
        </button>
      </div>
    </div>
  </ng-template>
  <ng-container *ngIf="showSearchInDropdown">
    <ng-template ng-header-tmp>
      <div class="input-icon-prepend"
          id="searchInputContainer">
        <svg class="icon-prepend text-gray-pantone"
            height="24"
            stroke="currentColor"
            stroke-width="1.5"
            width="24"
            viewBox="0 0 24 24">
          <use href="assets/images/icons/objects/search.svg#search"></use>
        </svg>
        <input class="form-control shadow-none round-input"
            placeholder="{{ 'COMMON.Search' | translate }}..."
            type="text"
            [(ngModel)]="inDropdownSearch"
            (ngModelChange)="onInDropdownSearch($event)">
      </div>
    </ng-template>
  </ng-container>
  <ng-template ng-option-tmp
      let-item$="item$">
    <div class="d-flex align-items-center fw-medium text-black-deep">
      <input *ngIf="showCheckbox"
          class="form-control checkbox-default default-size me-base-l8"
          title="{{ getLabel(item$.value) }}"
          type="checkbox"
          [checked]="item$.selected">
      <ng-container *ngIf="showImage">
        <ng-container *ngIf="choiceImageTemp; else defaultImageTemp">
          <ng-container [ngTemplateOutlet]="choiceImageTemp"
              [ngTemplateOutletContext]="{ item: item$.value }">
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-template #defaultImageTemp>
        <img alt="profile"
            class="img-default"
            [src]="getImage(item$.value) || defaultImage">
      </ng-template>
      <div class="flex-fill-0 w-0 align-self-stretch">
        <ng-container *ngIf="choiceLabelTemp; else defaultLabelTemp">
          <ng-container [ngTemplateOutlet]="choiceLabelTemp"
              [ngTemplateOutletContext]="{ item: item$.value }">
          </ng-container>
        </ng-container>
        <ng-template #defaultLabelTemp>
          <div class="d-flex flex-column justify-content-center h-100">
            <label class="mb-0">{{ getLabel(item$.value) }}</label>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-template>
</ng-select>