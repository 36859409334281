import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ThemeService } from '@shared/service/theme.service';

@Component({
  selector: 'app-input-type-number',
  templateUrl: './input-type-number.component.html',
  styleUrls: ['./input-type-number.component.scss'],
})
export class InputTypeNumberComponent {
  @Input() model: number;
  @Output() modelChange: EventEmitter<number> = new EventEmitter();
  @Input() min: number;
  @Input() max: number;
  displayIsTablet: boolean;
  constructor(private themeService: ThemeService) {
    this.sizeTablet();
  }

  sizeTablet() {
    const isTablet = this.themeService.isTablet();
    if (isTablet) {
      this.displayIsTablet = true;
    }
  }
  increment() {
    if (this.max) {
      if (this.model < this.max) {
        this.model++;
      }
    } else {
      this.model++;
    }
    this.emit();
  }

  decrement() {
    if (this.min) {
      if (this.model > this.min) {
        this.model--;
      }
    } else {
      this.model--;
    }
    this.emit();
  }

  emit() {
    setTimeout(() => {
      this.modelChange.emit(this.model);
    }, 700);
  }
}
