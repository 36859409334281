import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { ThemeService } from '../service/theme.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appSetTheme]',
})
export class SetThemeDirective implements OnInit, OnDestroy {
  themeList: any;

  @Input() colorName: string;
  /**
   * The list of css properties to set color.
   * ex. color, background-color, border-color
   */
  @Input({ transform: colorTargetTransformer })
  colorTargets: string[] = [];

  @Input() type: string;
  themeSubscription: Subscription[] = [];

  constructor(
    private themeService: ThemeService,
    private elRef: ElementRef,
    private renderer: Renderer2,
  ) {
    this.themeSubscription.push(
      this.themeService.data.subscribe((theme) => {
        this.themeList = theme;
        if (this.themeList) {
          switch (this.type) {
            case 'headerTable':
              this.setThemeInWeb(
                'header_table_color',
                'text_header_table_color',
              );
              break;
            case 'button':
              this.setThemeInWeb(
                'header_table_color',
                'text_header_table_color',
              );
              break;
            case 'submit':
              this.setThemeInWeb(
                'header_table_color',
                'text_header_table_color',
              );
              break;
          }
        }
      }),
    );
  }

  ngOnInit() {
    if (this.type) {
      this.themeService.themeSetting();
    }
    this.setColorToCssTarget();
  }

  ngOnDestroy(): void {
    this.themeSubscription?.forEach((item) => {
      try {
        item.unsubscribe();
      } catch (e) {
        console.error(e);
      }
    });
  }

  setColorToCssTarget(): void {
    if (!this.colorTargets.length || !this.colorName) {
      return;
    }
    const color = this.themeService.getHslColorValueByName(
      this.colorName,
    );
    this.colorTargets.forEach((prop) => {
      this.renderer.setStyle(
        this.elRef.nativeElement,
        prop,
        `hsl(${color})`,
      );
    });
  }

  setThemeInWeb(bg: string, text: string) {
    this.renderer.setStyle(
      this.elRef.nativeElement,
      'background',
      this.themeService.themeList[bg],
    );
    this.renderer.setStyle(
      this.elRef.nativeElement,
      'color',
      this.themeService.themeList[text],
    );
  }
}

function colorTargetTransformer(value: any): string[] {
  if (typeof value === 'string') {
    return value.split(',');
  } else if (Array.isArray(value)) {
    return value;
  }
  return [];
}
