import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GroundColor } from '@shared/models/common.model';
import { ThemeService } from '@shared/service/theme.service';
import {
  asyncScheduler,
  debounceTime,
  delay,
  fromEvent,
  interval,
  Subject,
  Subscription,
  takeUntil,
  takeWhile,
} from 'rxjs';
import { UtilsService } from 'src/app/core/services/utils.service';
import { SliderTabBarService } from './slider-tab-bar.service';

@Component({
  selector: 'app-slide-tab-bar',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './slide-tab-bar.component.html',
  styleUrls: ['./slide-tab-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlideTabBarComponent
  implements OnChanges, OnInit, AfterViewInit, OnDestroy
{
  @Input() standalone = false;
  @Input() enableAnimation = true;
  @Input() showIcon: 'show' | 'exist' | 'none' | string = 'exist';
  @Input() showBadge = false;
  @Input() tabs: Tab[] = [];
  @Input() selectedTab?: string | null;
  @Output() selectedTabChange = new EventEmitter<
    string | undefined | null
  >();
  @ViewChild('tabWrapper') set onTabWrapperChange(
    elRef: ElementRef<HTMLDivElement>,
  ) {
    this.tabWrapperElRef = elRef;
  }

  isDesktop = false;
  isResizing = false;
  isScrollable = false;
  navigateReleaseTakeUntil$: Subject<void> = new Subject();
  navigateHoldInterval$ = interval(20).pipe(
    delay(100),
    takeUntil(this.navigateReleaseTakeUntil$),
  );
  selectedTabItemIndex?: number | null;
  tabItemWidths: number[] = [];
  tabWrapperElRef?: ElementRef<HTMLDivElement>;

  private cdRef = inject(ChangeDetectorRef);
  private compSubs?: Subscription;
  private hostObserver?: ResizeObserver;
  private tabRowObserver?: ResizeObserver;
  private themeService = inject(ThemeService);
  private utils = inject(UtilsService);

  constructor(
    private elRef: ElementRef,
    @Optional() private selfService: SliderTabBarService | null,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tabs']) {
      this.validateTabs();
      if (this.selfService) {
        this.selfService.tabs = changes['tabs'].currentValue;
      }
    }
    if (changes['selectedTab']) {
      this.updateSelectedTabItemIndex();
    }
  }

  ngOnInit(): void {
    this.subscribeBadgeChange();
  }

  ngAfterViewInit(): void {
    if (this.tabWrapperElRef) {
      const listener = fromEvent(
        this.tabWrapperElRef.nativeElement,
        'scroll',
      )
        .pipe(debounceTime(70))
        .subscribe({
          next: () => {
            this.cdRef.detectChanges();
          },
        });
      this.compSubs?.add(listener);
    }
    this.subscribeHostResize();
    asyncScheduler.schedule(() => {
      this.tabItemWidths = this.getTabItemsWidth();
      this.checkNavigateButtonVisibility();
      this.scrollNearestToSelectedTab();
      this.cdRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.compSubs?.unsubscribe();
    this.hostObserver?.disconnect();
    this.tabRowObserver?.disconnect();
  }

  checkNavigateButtonVisibility(): void {
    const tabWrapperElRef = this.tabWrapperElRef?.nativeElement;
    const scrollWidth = tabWrapperElRef?.scrollWidth || 0;
    const clientWidth = tabWrapperElRef?.clientWidth || 0;
    // The 4 value is compensate gx-1.
    this.isScrollable = scrollWidth > clientWidth + 4;
  }

  getLeftStartWidth(): number {
    if (this.selectedTabItemIndex == null) {
      return 0;
    }
    let accWidth = 0;
    for (let i = 0; i < this.selectedTabItemIndex; i++) {
      accWidth += this.tabItemWidths[i];
    }
    return accWidth;
  }

  getTabItemsWidth(): number[] {
    const tabItemElRefs = this.tabRowElRef?.children;
    if (!tabItemElRefs) {
      return [];
    }
    const widths: number[] = [];
    for (let i = 0; i < tabItemElRefs.length; i++) {
      const tabItemElRef = tabItemElRefs.item(i);
      widths.push(tabItemElRef?.clientWidth || 0);
    }
    return widths;
  }

  onNavigateHold(dir: 'left' | 'right'): void {
    const elRef = this.tabWrapperElRef?.nativeElement;
    if (!elRef) {
      return;
    }
    this.navigateHoldInterval$
      .pipe(
        takeWhile(() => {
          const scrollLeft = elRef?.scrollLeft || 0;
          const scrollWidth = elRef?.scrollWidth || 0;
          return dir === 'left'
            ? scrollLeft > 0 && this.isPreviousButtonVisible
            : scrollLeft < scrollWidth && this.isNextButtonVisible;
        }),
      )
      .subscribe({
        next: () => {
          elRef.scrollTo({
            left: elRef.scrollLeft + (dir === 'left' ? -50 : 50),
            behavior: 'smooth',
          });
        },
      });
  }

  onNavigateRelease(): void {
    this.navigateReleaseTakeUntil$.next();
  }

  onNextClick(): void {
    const elRef = this.tabWrapperElRef?.nativeElement;
    const clientWidth = elRef?.clientWidth || 0;
    const scrollLeft = elRef?.scrollLeft || 0;
    const scrollRight = scrollLeft + clientWidth;
    let accWidth = 0;
    for (const width of this.tabItemWidths) {
      accWidth += width;
      if (accWidth > scrollRight) {
        break;
      }
    }
    elRef?.scrollTo({
      left:
        scrollLeft + accWidth - scrollRight + this.navigateBtnOffset,
      behavior: 'smooth',
    });
  }

  onPreviousClick(): void {
    const elRef = this.tabWrapperElRef?.nativeElement;
    const scrollLeft = elRef?.scrollLeft || 0;
    let accWidth = 0;
    let lastWidth = 0;
    for (const width of this.tabItemWidths) {
      lastWidth = width;
      accWidth += width;
      if (accWidth > scrollLeft) {
        break;
      }
    }
    elRef?.scrollTo({
      left:
        scrollLeft -
        (lastWidth - (accWidth - scrollLeft)) -
        this.navigateBtnOffset,
      behavior: 'smooth',
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.isDesktop = !this.themeService.isTabletOrMobile();
    this.isResizing = true;
    this.tabItemWidths = this.getTabItemsWidth();
    this.checkNavigateButtonVisibility();
    this.cdRef.detectChanges();
    setTimeout(() => {
      this.isResizing = false;
      this.tabItemWidths = this.getTabItemsWidth();
      this.cdRef.detectChanges();
    }, 0); // The duration refer by css transition.
  }

  /**
   * Scrolls the tab bar to ensure that the currently selected tab is fully visible.
   * Adjusts the view if the selected tab is partially obscured by the navigation buttons.
   */
  scrollNearestToSelectedTab(): void {
    const tabIndex = this.tabs.findIndex(
      (tab) => tab.value === this.selectedTab,
    );
    const elRef = this.tabWrapperElRef?.nativeElement;
    const clientWidth = elRef?.clientWidth || 0;
    const scrollLeft = elRef?.scrollLeft || 0;
    const scrollRight = scrollLeft + clientWidth;
    let accWidth = 0;
    let lastWidth = 0;
    for (let i = 0; i <= tabIndex; i++) {
      const width = this.tabItemWidths[i];
      lastWidth = width;
      accWidth += width;
    }
    let targetScrollLeft = scrollLeft;
    if (accWidth > scrollRight - this.navigateBtnOffset) {
      targetScrollLeft =
        scrollLeft + accWidth - scrollRight + this.navigateBtnOffset;
    } else if (
      accWidth - lastWidth <
      scrollLeft + this.navigateBtnOffset
    ) {
      targetScrollLeft =
        accWidth - lastWidth - this.navigateBtnOffset;
    }
    elRef?.scrollTo({
      left: targetScrollLeft,
      behavior: 'smooth',
    });
  }

  scrollToEnd(dir: 'left' | 'right'): void {
    const elRef = this.tabWrapperElRef?.nativeElement;
    if (!elRef) {
      return;
    }
    elRef.scrollTo({
      left: dir === 'left' ? 0 : elRef.scrollWidth,
      behavior: 'smooth',
    });
  }

  selectTab(tab: Tab): void {
    if (this.standalone) {
      this.enableAnimation = false;
    }
    this.selectedTab = tab.value;
    this.selectedTabChange.emit(this.selectedTab);
    this.updateSelectedTabItemIndex();
  }

  subscribeBadgeChange(): void {
    if (!this.selfService) {
      console.warn('SliderTabBarService is not provided');
      return;
    }
    const subs = this.selfService.badgeChange.subscribe({
      next: () => {
        this.onResize();
      },
    });
    this.compSubs?.add(subs);
  }

  subscribeHostResize(): void {
    if (!this.elRef) {
      return;
    }
    this.hostObserver = new ResizeObserver(() => this.onResize());
    this.hostObserver.observe(this.elRef.nativeElement);
    if (this.tabRowElRef) {
      let widthTemp = 0;
      this.tabRowObserver = new ResizeObserver((entry) => {
        const targetWidth = entry[0].contentRect.width;
        // This condition is set to prevent imprecise rendering
        const diff = Math.abs(targetWidth - widthTemp) >= 2;
        widthTemp = targetWidth;
        if (diff) {
          this.onResize();
        }
      });
      this.tabRowObserver.observe(this.tabRowElRef);
    }
  }

  updateSelectedTabItemIndex(): void {
    this.selectedTabItemIndex = this.tabs.findIndex(
      (tab) => tab.value === this.selectedTab,
    );
    if (this.selectedTabItemIndex === -1) {
      this.selectedTabItemIndex = undefined;
    }
  }

  validateTabs(): void {
    this.tabs = this.tabs.map((tab) => {
      if (tab.iconPath) {
        tab.iconPath = this.utils.appendTagFromSvgPath(tab.iconPath);
      }
      return tab;
    });
  }

  get navigateBtnOffset(): number {
    return (this.isDesktop ? 44 : 32) * 0.75;
  }

  get isPreviousButtonVisible(): boolean {
    return (
      this.isScrollable &&
      (this.tabWrapperElRef?.nativeElement?.scrollLeft || 0) > 0
    );
  }

  get isNextButtonVisible(): boolean {
    const { scrollLeft, clientWidth, scrollWidth } = this
      .tabWrapperElRef?.nativeElement || {
      scrollLeft: 0,
      clientWidth: 0,
      scrollWidth: 0,
    };
    return (
      this.isScrollable && scrollLeft + clientWidth < scrollWidth
    );
  }

  get tabRowElRef(): Element | undefined {
    return this.tabWrapperElRef?.nativeElement.getElementsByClassName(
      'tab-row',
    )[0];
  }
}

export interface Tab {
  badge: number;
  color: Partial<GroundColor>;
  iconPath?: string;
  label: string;
  /**
   * Override inactive text and icon colors with a single color
   * instead of the foreground color.
   */
  defaultColor?: {
    text?: string;
    icon?: string;
  };
  value?: string | null;
}
